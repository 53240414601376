import QRCode from 'qrcode.react';
import StoreContext from "../../../store/RootStore";
import InteractionConsent from "./interactionConsent";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import Accounts from "../accounts";
import Seed from "../seed";
import InteractionEmpowerment from "./interactionEmpowerment";
import InteractionMerge from "./interactionMerge";
import NotchButton from "../../notchButton";
import ExpressSignup from "../expressSignup"
import Signup from "../signup";
import InteractionBackup from "./interactionBackup";
import {isMobile} from "react-device-detect";
import {ReactComponent as Error400} from "../../../assets/images/error-400.svg";
import {ReactComponent as NetworkError} from "../../../assets/images/network-error.svg";

import './index.sass'
import IDBTutorial from "../../IDBTutorial";


function InteractionLogin() {

    const {InteractionStore, FunctionStore, LangStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const currentStage = InteractionStore.checkInteractionStage();
    const customStage = InteractionStore.checkCustomStage();
    const [account, setAccount] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);

    function renderLogin(stage, customStage) {

        const login = () => {
            return (
                <>
                    <span className="login-options-text">{LangStore.getInstance().t("authorization.qr")}</span>
                    <QRCode className="login-qr" size={150} value={FunctionStore.getQrDeepLink("login")} />
                    {isMobile ? <NotchButton type="button" color="white" onClick={()=> window.location.href = FunctionStore.getQrDeepLink("login")} value={LangStore.getInstance().t("authorization.buttons.open")}/> : ""}
                    <div className="alternative-option-bar">
                        <hr className="alternative-option-bar-line"/>
                        <span className="alternative-option-bar-text">{LangStore.getInstance().t("authorization.stages.login.alt.login")}</span>
                        <hr className="alternative-option-bar-line"/>
                    </div>
                    {InteractionStore.checkInteraction().params.possible_ephemeral ?
                        <NotchButton type="button" color="black" onClick={()=> {
                            InteractionStore.setCustomStage("express_sign_up");
                            console.log(InteractionStore.checkCustomStage());
                        }} value={LangStore.getInstance().t("authorization.buttons.express")}/>
                        :
                        <NotchButton type="button" color="black" onClick={()=> {
                            InteractionStore.setCustomStage("sign_up");
                            console.log(InteractionStore.checkCustomStage());
                        }} value={LangStore.getInstance().t("authorization.buttons.signup")}/>
                    }
                    <NotchButton type="button" color="white" onClick={()=> {
                        InteractionStore.setCustomStage("seed_restore");
                        console.log(InteractionStore.checkCustomStage());
                    }} value={LangStore.getInstance().t("authorization.buttons.restore")}/>
                </>
            )
        }

        if(stage && !customStage) {
            switch (stage) {
                case "" :
                    throw new Error ("Error while rendering InteractionLogin: empty InteractionStage.");

                case "login" :
                    return login();

                case "select_account" :
                    return <Accounts sessions={InteractionStore.checkInteraction().sessions}/>

                case "consent" :
                    return <InteractionConsent/>

                case "empowerment" :
                    return <InteractionEmpowerment/>

                case "merge" :
                    return <InteractionMerge/>

                case "backup_seed":
                    return <InteractionBackup/>

                default:
                    return;
            }
        } else if (stage && customStage) {
            switch (customStage) {
                case "login" :
                    return login();

                case "seed_restore":
                case "seed_related_accounts":
                case "seed_add":
                    return <Seed/>

                case "express_sign_up":
                    return <ExpressSignup/>

                case "sign_up":
                case "sign_up_qr":
                case "seed_show":
                case "seed_verify":
                    return <Signup/>

                case "auth_error":
                    if (InteractionStore.checkAuthError()[0].includes("interaction")) {
                        return <div className="error">
                            <NetworkError className="error-image"/>
                            <h6 className="error-title">Network error: {InteractionStore.checkAuthError()[0]}</h6>
                            <p className="error-description">
                                {LangStore.getInstance().t("authorization.stages.auth_error.network.error")}<br/>
                                {InteractionStore.checkAuthError()[1]}
                            </p>
                        </div>
                    } else {
                        return <div className="error">
                            <Error400 className="error-image"/>
                            <h6 className="error-title">Error 400: {InteractionStore.checkAuthError()[0]}</h6>
                            <p className="error-description">
                                {InteractionStore.checkAuthError()[1]}
                            </p>
                        </div>
                    }

                case "idb_error":
                    return <IDBTutorial/>

                case "empowerment_seed":
                    return <InteractionEmpowerment/>

                default:
                    return;
            }
        }
    }

    //Lifecycle interactions

    useEffect(()=>{
        // Detecting IDB compatibility
        FunctionStore.checkIDBCompatibility();
    }, [FunctionStore])

    useEffect(()=>{
        // Fetching changes made with the op key to detect login with qr every 2 seconds
        if((currentStage === "login" && fetchingData && !customStage) || customStage === "login") {
            const interval = setInterval(() => FunctionStore.fetchQrScanResult(account, setAccount, setFetchingData), 5000);
            return () => clearInterval(interval);
        }
    }, [currentStage, fetchingData, account, customStage, FunctionStore])

    return (
        <>
            {renderLogin(currentStage, customStage)}
        </>
    )
}

export default observer(InteractionLogin);