import "./index.sass"
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import StoreContext from "../../../store/RootStore";
import Seed from "../../auth/seed";
import Preloader from "../../preloader";

function BackupAccount() {
    const [seedData, setSeedData] = useState({});
    const {FunctionStore, InteractionStore, AccountStore} = StoreContext();

    useEffect(() => {
        InteractionStore.setCustomStage("seed_show");
        FunctionStore.generateSeed(setSeedData)
    }, [FunctionStore, InteractionStore])

    useEffect(()=>{
        if (!InteractionStore.checkCustomStage()) {
            AccountStore.setCurrentStage("home")
        }
    }, [AccountStore, InteractionStore, InteractionStore.customStage])

    return (
        seedData.seed && seedData.seed.length ?
            InteractionStore.checkTransactionStatus() ?
                <div className="preloader-wrapper">
                    <Preloader/>
                    <span className="preloader-text">{InteractionStore.checkTransactionStatus()}</span>
                </div> :
                <Seed phrase={seedData.seed}
                      actionAfterVerify={() => {
                          if (AccountStore.getActiveAccount().type === "ephemeral") {
                            FunctionStore.backupEphemeralAccount(AccountStore.getActiveAccount(), InteractionStore.checkInteraction(), seedData.privateKey)
                          } else {
                            FunctionStore.backupOwnerAccount(AccountStore.getActiveAccount(), InteractionStore.checkInteraction(), seedData.privateKey)
                          }
                      }}/>
            : <Preloader/>
    )
}

export default observer(BackupAccount)