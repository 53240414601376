import './index.sass'
import "../../components/auth/box/index.sass"
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import Preloader from "../../components/preloader";
import StoreContext from "../../store/RootStore";
import Notification from "../../components/notification";
import Transaction from "../../components/accInfo/transaction";
import Box from "../../components/accInfo/box";
import Session from "../../components/accInfo/session";
import AccountSelector from "../../components/accInfo/accountSelector";
import BackupAccount from "../../components/accInfo/backupAccount";
import RestoreAccount from "../../components/accInfo/restoreAccount";

function AccountInfo() {
    const {FunctionStore, LangStore, InteractionStore, AccountStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const currentTab = AccountStore.getCurrentStage();
    const accounts = AccountStore.getAccounts();
    const selected = AccountStore.getSelectedAccount();


    const transactions = AccountStore.getActiveAccountTransactions();

    async function loadMore() {
        const currentAccount = AccountStore.getActiveAccount();
        const newPage        = await FunctionStore.getHistory(currentAccount.account, isLoadMore);

        newPage.result = [
            ...transactions.result,
            ...newPage.result
        ];

        AccountStore.setTransactions(newPage);
    };

    let isLoadMore = null;

    if (transactions && transactions.total) {
        const currentSize = Number(transactions.page_number) * transactions.page_size;
        if(currentSize < transactions.total) {
            isLoadMore = Number(transactions.page_number) + 1;
        };
    };

    useEffect(() => {
        let interaction = InteractionStore.checkInteraction();

        if (interaction) {
            AccountStore.setAccounts(interaction.sessions);
        } else {
            interaction = FunctionStore.authorizeToAccountInfo().then(interaction => {
                AccountStore.setAccounts(interaction.sessions);
            });
        }
    }, [AccountStore, FunctionStore, InteractionStore, InteractionStore.interaction, accounts, currentTab])

    function renderContent() {
        switch (currentTab) {
            case "home":
            case "sessions":
            case "tokens":
                if (InteractionStore.checkInteraction().id) {
                    if (AccountStore.getActiveAccount()) {
                        return (
                            <main className="account-info-content">
                                <div className="account-info-tabs">
                                    {AccountStore.getActiveAccount().guest ?
                                        <>
                                            <h6 className={"account-info-tab account-info-tab-guest" + (currentTab === "home" ? " account-info-tab-active" : "")}
                                                onClick={() => {
                                                    AccountStore.setCurrentStage("home");
                                                }}>{LangStore.getInstance().t("account.info.stages.info.home.title")}</h6>
                                            <h6 className={"account-info-tab account-info-tab-guest" + (currentTab === "tokens" ? " account-info-tab-active" : "")}
                                                onClick={() => {
                                                    AccountStore.setCurrentStage("tokens");
                                                }}>{LangStore.getInstance().t("account.info.stages.info.tokens.title")}</h6>
                                        </>
                                        : <>
                                            <h6 className={"account-info-tab" + (currentTab === "home" ? " account-info-tab-active" : "")}
                                                onClick={() => {
                                                    AccountStore.setCurrentStage("home");
                                                }}>{LangStore.getInstance().t("account.info.stages.info.home.title")}</h6>
                                            <h6 className={"account-info-tab" + (currentTab === "sessions" ? " account-info-tab-active" : "")}
                                                onClick={() => {
                                                    AccountStore.setCurrentStage("sessions");
                                                }}>{LangStore.getInstance().t("account.info.stages.info.sessions.title")}</h6>
                                            <h6 className={"account-info-tab" + (currentTab === "tokens" ? " account-info-tab-active" : "")}
                                                onClick={() => {
                                                    AccountStore.setCurrentStage("tokens");
                                                }}>{LangStore.getInstance().t("account.info.stages.info.tokens.title")}</h6>

                                          </>
                                    }
                                </div>
                                <div className="account-info-tabs-content">
                                    {
                                        currentTab === "home" ?
                                            transactions && transactions.total > 0  ?
                                                <>
                                                    { AccountStore.getActiveAccountTransactions().result.map((item, key) => (
                                                        <Transaction data={item} key={key}/>
                                                    ))}
                                                    { isLoadMore && <div className='load-more-button' onClick={loadMore}>more</div> }
                                                </>
                                                : transactions && transactions.total === 0 ?
                                                    <span className="account-info-tabs-content-empty">
                                                        {LangStore.getInstance().t("account.info.stages.info.home.empty")}
                                                    </span> : <Preloader/>

                                            :
                                            currentTab === "sessions" ?
                                                AccountStore.getActiveAccountSessions().length > 0 ?
                                                    AccountStore.getActiveAccountSessions().map(session => (
                                                        <Session key={session.client_info.client_id} data={session}/>
                                                    ))
                                                    : AccountStore.getActiveAccountSessions().length === 0 ?
                                                        <span className="account-info-tabs-content-empty">
                                                            {LangStore.getInstance().t("account.info.stages.info.sessions.empty")}
                                                        </span>  : <Preloader/>

                                                :
                                                currentTab === "tokens" ?
                                                    <span className="account-info-tabs-content-empty">
                                                        {LangStore.getInstance().t("account.info.stages.info.tokens.empty")}
                                                    </span>
                                                    // AccountStore.getActiveAccountTokens().length > 0 ?
                                                    //     AccountStore.getActiveAccountTokens().map(token => (
                                                    //         <Token data={token}/>
                                                    //     ))
                                                    //     : AccountStore.getActiveAccountTokens().length === 0 ?
                                                    //         <>
                                                    //             No tokens yet.
                                                    //         </> : <Preloader/>
                                                    : ""
                                    }
                                </div>
                            </main>
                        )
                    } else {
                        AccountStore.setCurrentStage("accounts");
                        break;
                    }
                } else {
                    return <Preloader/>
                }

            case "accounts":
                if (accounts.length > 0 && InteractionStore.checkInteraction().id) {
                    return <AccountSelector accounts={accounts} selected={selected}/>
                } else {
                    return <Preloader/>
                }

            case "backup_account":
                return <BackupAccount/>

            case "restore_account":
                return <RestoreAccount/>

            default:
                return <Preloader/>
        }

    }

    return (
        <div className="account-info">
            <Notification/>
            <Box stage={AccountStore.getCurrentStage()}>
                {renderContent()}
            </Box>
        </div>
    )
}

export default observer(AccountInfo);