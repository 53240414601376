import './index.sass'

export default function NotchButton({type, color, value, containerClassName, buttonClassName, ...attributes}) {

    return (
        <>
            {color === "black" ?
                type === "button" ?
                    <button className={`auth-button auth-black-button ${buttonClassName ? buttonClassName : ""}`} {...attributes}>{value}</button>

                    : type === "submit" ?
                        <input type="submit" value={value} className={`auth-button auth-black-button ${buttonClassName ? buttonClassName : ""}`}{...attributes}/>

                        : new Error("Error while rendering NotchButton: incorrect type")

                : type === "button" ?
                    <div className={`auth-button-wrapper auth-${color}-button-wrapper ${containerClassName ? containerClassName : ""}`}>
                        <button className={`auth-button auth-${color}-button ${buttonClassName ? buttonClassName : ""}`} {...attributes}>{value}</button></div>

                    : type === "submit" ? <div className={`auth-button-wrapper auth-${color}-button-wrapper ${containerClassName ? containerClassName : ""}`}>
                            <input type="submit" value={value} className={`auth-button auth-${color}-button ${buttonClassName ? buttonClassName : ""}`}{...attributes}/></div>

                        : new Error("Error while rendering NotchButton: incorrect type")
            }
        </>
    )
}