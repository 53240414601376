import './index.sass'
import {observer} from "mobx-react";
import Seed from "../seed";
import StoreContext from "../../../store/RootStore";
import {useEffect, useState} from "react";
import Preloader from "../../preloader";
import NotchButton from "../../notchButton";
import QRCode from 'qrcode.react';

function Signup() {
    const {InteractionStore, LangStore, FunctionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const [seedData, setSeedData] = useState({});

    useEffect(() => {
        FunctionStore.generateSeed(setSeedData);
        InteractionStore.setCustomStage("seed_show"); // this should be done after the user agrees to a warning
    }, [FunctionStore, InteractionStore]);

    useEffect(() => {
        if (InteractionStore.customStage === "sign_up_qr") {
            FunctionStore.registerAccount(seedData.privateKey);
        }
    }, [InteractionStore.customStage, FunctionStore, seedData.privateKey])

    return (InteractionStore.checkTransactionStatus() ?
            <>
                <Preloader/>
                <span className="preloader-text">{InteractionStore.checkTransactionStatus()}</span>
            </> : <>
            {InteractionStore.checkCustomStage() === "sign_up_qr" ?
                <>
                    <span className="signup-qr-text">{LangStore.getInstance().t("authorization.stages.sign_up_qr.description")}</span>
                    <QRCode className="signup-qr" size={150} value={FunctionStore.getQrDeepLink("add_account", seedData)} />
                    <hr className="box-black-hr"/>
                    <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.skip")}
                                 disabled={InteractionStore.checkTransactionStatus()} color="black" onClick={() => FunctionStore.loginWithNewAccount(seedData.pubKey)}/>
                </> : <Seed phrase={seedData.seed ? seedData.seed : ""} stageAfterVerify="sign_up_qr"/>}
        </>
    )
}

export default observer(Signup)