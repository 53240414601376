import "./index.sass"
import {observer} from "mobx-react";
import Dropdown from "../../dropdown";
import StoreContext from "../../../store/RootStore";
import {useState} from "react";
import ExternalImage from 'react-external-image'

function Session({data}) {
    const {LangStore, FunctionStore, InteractionStore, AccountStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const evmAddress = AccountStore.getActiveAccount().accountData.evmAddress;
    const nativeAddress = AccountStore.getActiveAccount().account;

    function SessionHeader() {

        return (
            <div className="account-info-session-header">
                <div className="account-info-session-header-image-wrapper">
                    <ExternalImage src={"https://ipfs.io/ipfs/" + data.client_info.icon_id} fallbackImages={[require("../../../assets/images/session-placeholder.png").default]} alt="icon" className="account-info-session-header-image"/>
                </div>
                <span className="account-info-session-header-text">{data.client_info.client_name}</span>
            </div>
        )
    }

    return (
        <div className="account-info-session-wrapper">
            <Dropdown title={<SessionHeader/>} className="account-info-session">
                <div className="account-info-session-data" onClick={async ()=>{
                    await navigator.clipboard.writeText(AccountStore.getActiveAccount().accountData.evmAddress)
                }}>
                    <span className="account-info-session-data-subtitle">Account address (EVM):</span>
                    <h6 className="account-info-session-data-title">{evmAddress.slice(0, evmAddress.length / 4).concat("........").concat(evmAddress.slice(evmAddress.length - evmAddress.length / 4, evmAddress.length))}</h6>
                </div>
                <div className="account-info-session-data" onClick={async ()=>{
                    await navigator.clipboard.writeText(AccountStore.getActiveAccount().account)
                }}>
                    <span className="account-info-session-data-subtitle">Account address (Native):</span>
                    <h6 className="account-info-session-data-title">{nativeAddress.slice(0, nativeAddress.length / 4).concat("........").concat(nativeAddress.slice(nativeAddress.length - nativeAddress.length / 4, nativeAddress.length))}</h6>
                </div>
                <div className="account-info-session-data account-info-session-data-last" onClick={async ()=>{
                    await navigator.clipboard.writeText(AccountStore.getActiveAccount().account)
                }}>
                    <span className="account-info-session-data-subtitle">Website link: </span>
                    <a className="account-info-session-data-title" href={data.client_info.client_uri} target="_blank" rel="noreferrer">{data.client_info.client_uri}</a>
                </div>
                <button className="account-info-session-terminate" onClick={()=>FunctionStore.terminateAuthorization(InteractionStore.checkInteraction(), AccountStore.getActiveAccount().op_key, data)}>Terminate</button>
            </Dropdown>
        </div>
    )
}

export default observer(Session);