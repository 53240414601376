import './index.sass'
import AccountDropDown from "./accountDropDown";
import StoreContext from "../../../store/RootStore";
import {useState} from "react";
import NotchButton from "../../notchButton";
import Preloader from "../../preloader";
import {observer} from "mobx-react";

function Accounts({sessions}) {
    const {LangStore, InteractionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    return (
        <>
            {InteractionStore.checkTransactionStatus() ?
                <div className="accounts-preloader">
                    <Preloader/>
                    <p className="preloader-text">{InteractionStore.checkTransactionStatus()}</p>
                </div> :
                <>
                    <div className="accounts-wrapper">
                        <h4 className="accounts-title">{LangStore.getInstance().t("authorization.stages.select_account.title")}</h4>
                        {sessions.map((item, index) => <AccountDropDown key={"account-" + index} session={item}/>)}
                    </div>
                    <div className="alternative-option-bar">
                        <hr className="alternative-option-bar-line"/>
                        <span className="alternative-option-bar-text">{LangStore.getInstance().t("authorization.stages.login.alt.login")}</span>
                        <hr className="alternative-option-bar-line"/>
                    </div>
                    <NotchButton type="button" color="black" value={LangStore.getInstance().t("authorization.buttons.connect")} onClick={() => {
                        InteractionStore.setCustomStage("login");
                        console.log(InteractionStore.checkCustomStage());
                    }}/>
                </>}
        </>
    )
}

export default observer(Accounts);