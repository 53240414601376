import './index.sass'

export default function AccountTicker () {
    return (
        <div className="ticker-wrap">
            <div className="ticker">
                <div className="ticker-item"></div>
            </div>
        </div>
    )
}