import './index.sass';

export default function Preloader({className}) {
    return (
        <div className={`preloader ${className ? className : ""}`}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}