import "./index.sass"
import {observer} from "mobx-react";
import AccountDropDown from "../../auth/accounts/accountDropDown";
import StoreContext from "../../../store/RootStore";
import {ReactComponent as ImportAccountIcon} from '../../../assets/images/import-account.svg';
import {ReactComponent as RightBlackArrow} from '../../../assets/images/right-black-arrow.svg';
import {useState} from "react";
import Preloader from "../../../components/preloader";

function AccountSelector({accounts, selected}) {
    const {AccountStore, FunctionStore, LangStore, InteractionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.

    async function setActiveAccount(account) {
        account.accountData = await FunctionStore.fetchAccountData(account.account);
        AccountStore.setActiveAccount(account);
        AccountStore.setCurrentStage("home");
    }

    return (
        <>
            { InteractionStore.checkTransactionStatus() ?
                <div className="account-selector-preloader-wrapper">
                    <Preloader/>
                    <h6 className="account-selector-preloader-text">{InteractionStore.checkTransactionStatus()}</h6>
                </div>
                :
                <div className="account-selector">
                    {accounts.map((account, index) => (
                        <AccountDropDown key={account.account + `-#${index}`} account={account.account}
                                        session={account.session} onClick={() => {
                                            AccountStore.reset();
                                            setActiveAccount(account)
                                        }
                        }/>
                    ))}
                    <hr className="box-black-hr"/>
                    <div className="account-selector-buttons">
                        {/* Implement later */}
                        {/*<button className="account-selector-button">*/}
                        {/*    <div className="account-selector-button-wrapper">*/}
                        {/*        <CreateAccountIcon className="account-selector-button-icon"/>*/}
                        {/*        <span className="account-selector-button-text">{LangStore.getInstance().t("authorization.buttons.create.account")}</span>*/}
                        {/*    </div>*/}
                        {/*    <RightBlackArrow className="account-selector-button-arrow"/>*/}
                        {/*</button>*/}
                        <button className="account-selector-button" onClick={()=>{
                            AccountStore.setCurrentStage("restore_account")
                        }}>
                            <div className="account-selector-button-wrapper">
                                <ImportAccountIcon className="account-selector-button-icon"/>
                                <span className="account-selector-button-text">{LangStore.getInstance().t("authorization.buttons.import.account")}</span>
                            </div>
                            <RightBlackArrow className="account-selector-button-arrow"/>
                        </button>
                    </div>
                </div>
        }
        </>
    )
}

export default observer(AccountSelector)