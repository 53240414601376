import { makeAutoObservable } from "mobx";
import i18nInstance from "../functions/i18n";

class LangStore {
    instance = i18nInstance;
    language = i18nInstance.language;
    languageFullName = i18nInstance.t('lang_full_name');

    constructor() {
        makeAutoObservable(this);
    }

    getInstance() {
        return this.instance
    }

    changeLang(newLang) {
        i18nInstance.changeLanguage(newLang).then((promise)=>{return promise}).catch((e) => {return e})
        this.instance = i18nInstance;
        this.language = i18nInstance.language;
        this.languageFullName = i18nInstance.t("lang_full_name");
    }
}

export default new LangStore();