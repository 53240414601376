export default function getTranslations(translations, languageList) {
    const context = require.context("../assets/text", false, /.json$/);
    const languages = [];
    const resources = {};
    context.keys().forEach((key) => {
        const fileName = key.replace('./', '');
        const resource = require(`../assets/text/${fileName}`);
        const namespace = fileName.replace('.json', '');
        resources[namespace] = {
            translation: resource
        }
        languages.push({
            name: resource.lang_full_name,
            namespace: namespace,
            country_code: resource.country_code.toUpperCase()
        })
    });

    if(translations && languageList){
        return [resources, languages];
    } else if (translations && !languageList) {
        return resources;
    } else if(!translations && languageList) {
        return languages;
    } else {
        throw new Error("Error in function getTranslations: invalid input data");
    }
}