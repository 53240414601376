import "./index.sass"
import {observer} from "mobx-react";
import {useEffect} from "react";
import Seed from "../../auth/seed";
import StoreContext from "../../../store/RootStore";

function RestoreAccount() {
    const {InteractionStore, AccountStore} = StoreContext();

    useEffect(()=>{
        InteractionStore.setCustomStage("seed_restore");
    }, [InteractionStore])

    useEffect(()=>{
        if (!InteractionStore.checkCustomStage()) {
            AccountStore.setCurrentStage("home")
        }
    }, [AccountStore, InteractionStore, InteractionStore.customStage])

    return <Seed/>
}

export default observer(RestoreAccount);