import StoreContext from "../../../../store/RootStore";
import {observer} from 'mobx-react';
import ChooseLangModal from '../../../chooseLangModal'
import './index.sass'
import {useState} from "react";

function BoxFooter() {
    const {LangStore} = StoreContext();
    const [modalOpened, setModalOpened] = useState(false);

    return (
        <footer className="box-footer">
            <button className="box-footer-lang-switcher" onClick={() => setModalOpened(true)}>
                {LangStore.languageFullName}
            </button>
            <ChooseLangModal opened={modalOpened} setOpened={setModalOpened}/>
            <div className="box-footer-links">
                <a href="/terms" rel="noreferrer" target="_blank" className="box-footer-link">{LangStore.getInstance().t("authorization.terms")}</a>
                <a href="/privacy" rel="noreferrer" target="_blank" className="box-footer-link">{LangStore.getInstance().t("authorization.privacy")}</a>
            </div>
        </footer>
    )
}

export default observer(BoxFooter)