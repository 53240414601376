import { useEffect, useCallback } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import AccountInfo from "../../pages/accountInfo";
import StoreContext from "../../store/RootStore";

export default function RedirectComponent() {
    const {FunctionStore, AccountStore} = StoreContext();
    const data = useMatch('/account/:id');
    const navigate = useNavigate();

    const redirectToExternalLink = useCallback(async () => {
        const interaction = await FunctionStore.authorizeToAccountInfo();
        const accounts = interaction.sessions;

        if (data && data.params && data.params.id) {
            if (interaction.sessions.filter(session => session.account === data.params.id).length === 0) {
                const accountData = await FunctionStore.fetchAccountData(data.params.id);
                if (typeof accountData === "string") {
                    navigate("/");
                } else {
                    AccountStore.setActiveAccount({account: data.params.id, guest: true, accountData: accountData});
                    AccountStore.setSelectedAccount(data.params.id);
                    AccountStore.setCurrentStage("home");
                }
            } else {
                const account = interaction.sessions.filter(session => session.account === data.params.id)[0];
                account.accountData = await FunctionStore.fetchAccountData(data.params.id);
                AccountStore.setActiveAccount(account);
                AccountStore.setSelectedAccount(data.params.id);
                AccountStore.setCurrentStage("home");
            }
        } else if (!accounts.length) {
            window.location.replace(window.location.href.split("//")[0] + "//dev." + window.location.href.split("//")[1]);
        } else {
            AccountStore.setAccounts(accounts);
            AccountStore.setCurrentStage("accounts");
        }
    }, [AccountStore, FunctionStore, data, navigate]);

    useEffect(() => {
        redirectToExternalLink();
    }, [data, redirectToExternalLink]);

    return (
        <AccountInfo/>
    );
}