import "./index.sass"
import SwipeableTabs from 'react-swipeable-tabs';
import AccountDropDown from "../../auth/accounts/accountDropDown";
import {useState} from "react";
import {isMobile} from "react-device-detect";
import SwipeableViews from 'react-swipeable-views';

export default function AddressSwiper({evmAddress, nativeAddress}) {
    const [activeItemIndex, setActiveItemIndex] = useState(0);


    const Tab = ({address, active, type}) => {
        const addressShort = isMobile ? address.slice(0, address.length / 6).concat("........").concat(address.slice(address.length - address.length / 6, address.length)) : address.slice(0, address.length / 4).concat("........").concat(address.slice(address.length - address.length / 5, address.length));
        return <AccountDropDown addressObject={{address, addressShort, type}}
                                copyAccountAddressOnClick
                                disabled={!active}
                                additionalClassName={`address-swiper-item ${active ? "address-swiper-item-active" : ""}`}/>
    }

    const items = [evmAddress, nativeAddress].map((item, index) => (
        <Tab address={item} key={item + index} active={activeItemIndex === index} type={item === evmAddress ? "EVM" : "Native"}/>
    ));

    return (
        <div className="address-swiper">
            {!isMobile ?
            <SwipeableTabs
                noFirstLeftPadding={false}
                noLastRightPadding={false}
                fitItems={false}
                alignCenter={false}
                activeItemIndex={activeItemIndex}
                onItemClick={(item, index) => setActiveItemIndex(index)}
                items={items}
                borderThickness={0}
                safeMargin={0}
            /> :
            <SwipeableViews>
                {[evmAddress, nativeAddress].map((item, index) => (
                    <Tab address={item} key={index+item} active={activeItemIndex === index} type={item === evmAddress ? "EVM" : "Native"}/>
                ))}
            </SwipeableViews>
            }
        </div>
    );
}