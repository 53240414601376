import {makeAutoObservable, toJS} from "mobx";
import {agent} from "../functions/agent";
import InteractionStore from "./InteractionStore";
import LangStore from "./LangStore";
import AccountStore from "./AccountStore";
import {toast} from "react-toastify";
import * as device from "react-device-detect";
import * as bip39 from "bip39";
import nacl from "tweetnacl";
import bs58 from "bs58";
import Web3 from 'web3';

let browserName = device.browserName;
let globalSource;

if (window.navigator.brave !== undefined) {
    if (window.navigator.brave.isBrave.name === "isBrave") {
        browserName = 'Brave'
    };
};

class FunctionInteractionStore {
    error = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    compareArraysWithoutOrder(arr1, arr2) {
        // this is a basic .isEqual function without taking order of elements into account.
        // warning: the function compares values with data types and doesn't support multi-dimensional arrays because there is no need
        const array1 = Array.from(arr1);
        const array2 = Array.from(arr2);
        array1.sort();
        array2.sort();

        let result = true;

        if (array1.length !== array2.length) result = false;

        array1.forEach((item, index) => {
            if (item !== array2[index]) result = false
        })

        return result
    }

    handleError(error, nonCritical) {
        if (typeof error === "object") {
            console.log("ERROR: ", error)
            if ((error.message && error.message.includes("interaction")) || (error.description && error.description.includes("interaction"))) {
                InteractionStore.setAuthError(error);
            }
            error = error.message || error.description
        }

        const ErrorNotification = () => (
            <div className="notification-container">
                <img src={require("../assets/images/error-icon.svg").default} className="notification-icon"
                     alt="error icon"/>
                <div className="notification-content">
                    <h6 className="notification-header">{LangStore.getInstance().t("authorization.notifications.error")}</h6>
                    <p className="notification-text">{String(error).toLowerCase().includes("network") || String(error).toLowerCase().includes("fetch") ? LangStore.getInstance().t("authorization.notifications.network.error") : error}</p>
                </div>
            </div>
        )

        toast.error(<ErrorNotification/>, {
            className: "notification-error",
        });
        console.error(error);

        if (!nonCritical && !InteractionStore.checkAuthError()) {
            InteractionStore.setCustomStage(null) // reverting to the default screen in case of error
            InteractionStore.setTransactionStatus(null)
        }
    }

    handleSuccess(message) {
        const SuccessNotification = () => (
            <div className="notification-container">
                <img src={require("../assets/images/success-icon.svg").default} className="notification-icon"
                     alt="success icon"/>
                <div className="notification-content">
                    <h6 className="notification-header">{LangStore.getInstance().t("authorization.notifications.success")}</h6>
                    <p className="notification-text">{message}</p>
                </div>
            </div>
        )

        toast.success(<SuccessNotification/>, {
            className: "notification-success",
            autoClose: 2000
        })
    }

    async handleInteraction(interactionID) {
        try {
            const response = await agent.interaction({id: interactionID});
            InteractionStore.setInteractionData(response.interaction);
            console.log("interactionHandle:", toJS(InteractionStore.interaction));
            console.log("interactionStage:", InteractionStore.interactionStage)
            console.log("redirectUri:", InteractionStore.redirectUri)

            // Add listeners again if popup was reloaded
            if (InteractionStore.interaction.params.mode === 'popup' && !globalSource) {
                agent.addEventListener();
                window.addEventListener("message", (event) => {
                    if (!InteractionStore.interaction.client || !InteractionStore.interaction.client.redirect_uris.map((url) => {
                        try {
                            const protocol = url.split('/')[0];
                            const host = url.split('/')[2];
                            return protocol + '//' + host;
                        } catch (_) {
                            return ''
                        }
                    }).includes(event.origin)) return;
    
                    if (!globalSource) {
                        globalSource = event.source;
                    }
                });
            };
        } catch (e) {
            this.handleError(e)
        }
    }

    handleResponse(response) {

        if (response.redirect) {
            if (
                InteractionStore.checkInteraction().params.mode === 'redirect' || 
                InteractionStore.checkInteraction().params.mode === 'mobile'
            ) {
                window.location.replace(response.redirect);
            } else {
                if (globalSource) {
                    globalSource.postMessage(response.redirect, InteractionStore.checkInteraction().client.redirect_uri);
                }
            }
        } else if (response.success) {
            console.log("Handled interaction.")
        } else {
            this.handleInteraction(response.interaction.id);
        }
    }

    async resolveToken(token, nav) {
        try {
            const response = await agent.authorize({token});
            nav(`${response.interaction.id}`, {replace: true});

            agent.addEventListener();

            window.addEventListener("message", (event) => {
                if (!response.interaction.client || !response.interaction.client.redirect_uris.map((url) => {
                    try {
                        const protocol = url.split('/')[0];
                        const host = url.split('/')[2];
                        return protocol + '//' + host;
                    } catch (_) {
                        return ''
                    }
                }).includes(event.origin)) return;

                if (!globalSource) {
                    console.log("got source for sending responses!")
                    globalSource = event.source;
                }
            });
        } catch (e) {
            InteractionStore.setAuthError(e);
            this.handleError(e);
        }
    }

    async cancelInteraction(interactionID) {
        await agent.cancelInteraction(interactionID)
            .then((response) => this.handleResponse(response))
            .catch((error) => this.handleError(error))
    }

    finishInteractionAsync(interactionID, ...params) {
        return agent.finishInteraction(interactionID, ...params)
    };

    async finishInteraction(interactionID, ...params) {
        await agent.finishInteraction(interactionID, ...params)
            .then(response => {
                this.handleResponse(response)
            })
            .catch(e => this.handleError(e))
    };

    async initalizeEphemeral(ownerPublic) {
        await agent.provider.client.sendMessage({
            transaction_name: 'initializeEphemeral',
            params: {
                secret: ownerPublic,
                scopes: InteractionStore.checkInteraction().params.scope,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: (error, success) => {
                if (success) {
                    this.handleSuccess(LangStore.getInstance().t("authorization.notifications.registered"));
                } else {
                    this.handleError(error)
                }
            }
        });
    }

    deriveSeed(seed) {
        const ed25519 = require("ed25519-hd-key");
        return ed25519.derivePath("m/44'/5655640'/0'/0'", seed).key
    }

    getQrDeepLink(type, seedData) {
        const currentInteraction = InteractionStore.checkInteraction();
        switch (type) {
            case "login":
                return (
                    process.env.REACT_APP_LOGIN_DEEPLINK +
                    `?action=login&agent_type=${browserName}&client_id=${currentInteraction.client.client_id}` +
                    `&op_key=${currentInteraction.op_key}&scope=${currentInteraction.params.scope}&csrf=${currentInteraction.params.csrf_token}` +
                    `&sponsor_host=${currentInteraction.params.transactions_sponsor_api_host}&sponsor_pub_key=${currentInteraction.params.transactions_sponsor_pub_key}` +
                    `&network=${process.env.REACT_APP_ENV}`
                )
            case "empowerment":
                return (
                    process.env.REACT_APP_LOGIN_DEEPLINK +
                    `?action=empowerment&agent_type=${browserName}&address=${currentInteraction.session.account}&client_id=${currentInteraction.client.client_id}` +
                    `&op_key=${currentInteraction.session.op_key}&scope=${currentInteraction.empowerment}&csrf=${currentInteraction.params.csrf_token}` +
                    `&sponsor_host=${currentInteraction.params.transactions_sponsor_api_host}&sponsor_pub_key=${currentInteraction.params.transactions_sponsor_pub_key}` +
                    `&network=${process.env.REACT_APP_ENV}`
                )
            case "add_account":
                if (!seedData) {
                    this.handleError("Seed data undefined.");
                }
                return `?action=add_account&seed=${seedData.seed}&address=${seedData.address}&network=${process.env.REACT_APP_ENV}`
            default:
                return;
        }
    }

    async fetchQrScanResult(account, setAccount, setFetchingData) {
        console.log("interval iterating")
        if (account) {
            console.log("got account: ", account)
            setFetchingData(false);
            try {
                const accountData = await agent.provider.client.getAccountData(account);
                if (!accountData.operational_keys[InteractionStore.checkInteraction().op_key]) throw new Error("Operational key does not have enough permissions");
                await this.finishInteraction(InteractionStore.checkInteraction().id, {
                    mergeWithLastSubmission: false,
                    login: account,
                    setSessionType: "operational",
                });
                console.log(toJS(InteractionStore.checkInteraction()))
                InteractionStore.setCustomStage(null);
            } catch (e) {
                this.handleError(e);
            }

        } else {
            try {
                const value = await agent.provider.client.connection.getVelasAccountsByOperationalKey(InteractionStore.checkInteraction().op_key);
                console.log("Got value: ", value[0])
                if (value.length) setAccount(value[0]);

            } catch (e) {
                this.handleError(e);
            }
        }
    }

    async fetchScopeChanges(setFetchingData) {
        const currentInteraction = InteractionStore.checkInteraction();
        console.log("Scope Interval Iterating");
        const accountData = await agent.provider.client.getAccountData(currentInteraction.session.account);
        const currentScopes = await agent.provider.client.getCurrentAccountPrograms(accountData, currentInteraction.session.op_key, agent.provider.sc.VELAS_ACCOUNT_PROGRAM_ADDRESS)
        console.log(accountData)
        console.log("current scopes", currentScopes);
        if (currentInteraction.empowerment.every(item=>currentScopes.includes(item))) {
            console.log("Success, scopes are equal.")
            setFetchingData(false);
            try {
                await this.finishInteraction(currentInteraction.id, {
                    mergeWithLastSubmission: true,
                })
            } catch (e) {
                this.handleError(e);
            }
        }
    }

    async giveConsent() {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.approving"))
        await this.finishInteraction(InteractionStore.checkInteraction().id, {
            mergeWithLastSubmission: true,
            consent: {rejectedScopes: []},
        })
        // we don't change the transaction status to null because this is the final action before the redirect and the preloader must stay in place
    }

    async mergeAccounts(session, mergeWith, mergeTo) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.merging"))

        await agent.provider.client.sendMessage({
            transaction_name: 'mergeOperationalKeysTransaction',
            params: {
                ownerOrOperationalToSignTx: session.op_key,
                account: session.account,
                publicKeyOperationalToMergeWith: mergeWith,
                publicKeyOperationalToMergeTo : mergeTo,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: (error, success) => {
                if (success) {
                    this.finishInteractionAsync(InteractionStore.checkInteraction().id, {
                        mergeWithLastSubmission: true,
                        merge: true
                    }).then(response => {
                        InteractionStore.setTransactionStatus(null);
                        this.handleResponse(response);
                    })
                    .catch(e => this.handleError(e));
                } else {
                    InteractionStore.setTransactionStatus(null);
                    this.handleError(error);
                }
            },
        });
    }

    async validateSeed(seedPhrase) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.verifying"));
        try {
            if (!bip39.validateMnemonic(seedPhrase)) throw new Error(LangStore.getInstance().t("authorization.notifications.incorrect.seed"))

            const seed = await bip39.mnemonicToSeed(seedPhrase);
            const seedHex = seed.slice(0, 32).toString("hex");
            const derivedSeed = this.deriveSeed(seedHex);
            const keyPair = nacl.sign.keyPair.fromSeed(derivedSeed);
            const owner_address = bs58.encode(keyPair.publicKey);
            const owner_private = bs58.encode(keyPair.secretKey);

            console.log("trying to find account on the blockchain...")
            InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.finding"))
            const addresses = await agent.provider.client.connection.getVelasAccountsByOwnerKey(owner_address);
            if (addresses.length === 0) throw new Error(LangStore.getInstance().t("authorization.notifications.not.found"));

            if (InteractionStore.checkCustomStage() !== "empowerment_seed") { // check whether we are on empowerment with seed so that the preloader doesn't turn off until the next action
                InteractionStore.setTransactionStatus(null)
            }
            return {
                addresses: addresses,
                owner_key: owner_address,
                owner_private: owner_private
            }
        } catch (e) {
            InteractionStore.setTransactionStatus(null);
            this.handleError(e.message, true);
            return null;
        }
    }

    async addAcccount(accountData) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.loading"))
        try {
            const account = await agent.provider.client.getAccountData(accountData.address);
            if (!account) {
                throw new Error(LangStore.getInstance().t("authorization.notifications.not.found"));
            } else if (!account.owner_keys.includes(accountData.owner_key)) {
                throw new Error(`Specified seed phrase isn't connected to ${accountData.address}`);
            } else if (InteractionStore.checkInteraction().sessions && InteractionStore.checkInteraction().sessions.filter(session => session.account === accountData.address).length > 0){
                throw new Error(LangStore.getInstance().t("authorization.notifications.already.added"))
            }
        } catch (e) {
            this.handleError(e);
            if (AccountStore.getCurrentStage()) {
                AccountStore.setCurrentStage("accounts");
            }
            return;
        }
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.adding"))
        await agent.provider.client.sendMessage({
            transaction_name: 'addOperationalAddressTransaction',
            params: {
                transaction_signer:         accountData.owner_private,
                velas_account:              accountData.address,
                new_operational_public_key: InteractionStore.checkInteraction().op_key,
                scopes: InteractionStore.checkInteraction().params.scope,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: (error, success) => {
                if (success) {
                    this.finishInteraction(InteractionStore.checkInteraction().id, {
                        mergeWithLastSubmission: false,
                        login: accountData.address,
                        setSessionType: "operational"
                    }).then(() => {
                        if (AccountStore.getCurrentStage()) {
                            AccountStore.reset();
                            this.authorizeToAccountInfo();
                            AccountStore.setCurrentStage("accounts");
                        }
                        InteractionStore.setTransactionStatus(null);
                        InteractionStore.setCustomStage(null);
                    })
                } else {
                    InteractionStore.setTransactionStatus(null);
                    this.handleError(error);
                }
            },
        });
    }

    async sendTerminateTransaction(numberOfTries, tryNumber, session) {
        await agent.provider.client.sendMessage({
            transaction_name: 'removeOperationalAddressTransaction',
            params: {
                ownerOrOperationalToSignTx: session.op_key,
                account: session.account,
                publicKeyOperationalToRemove: session.op_key,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: async (error, success) => {
                if (success) {
                    await agent.removeSession(InteractionStore.checkInteraction().id, session.op_key); //to-do: change op_key to session_key
                    await this.handleInteraction(InteractionStore.checkInteraction().id);
                    this.handleSuccess(LangStore.getInstance().t("authorization.notifications.terminated"));
                    InteractionStore.setTransactionStatus(null);
                } else if (error && tryNumber < numberOfTries) {
                    console.error(error.description.concat(` Try №${tryNumber}`));
                    tryNumber++;
                    this.sendTerminateTransaction(numberOfTries, tryNumber, session)
                } else {
                    this.handleError(error.description.concat(`. After ${tryNumber} tries.`));
                    InteractionStore.setTransactionStatus(null);
                }
            }
        });
    }

    async terminateSession(session) {
        if (session.type === "operational") {
            const numberOfTries = 3; //change this if more tries to send the terminate transactions are needed
            let tryNumber = 1;

            InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.terminating"));
            await this.sendTerminateTransaction(numberOfTries, tryNumber, session)
        } else {
            await agent.removeSession(InteractionStore.checkInteraction().id, session.op_key); //to-do: change op_key to session_key
            await this.handleInteraction(InteractionStore.checkInteraction().id);
            this.handleSuccess(LangStore.getInstance().t("authorization.notifications.terminated"))
        }
    }

    async generateSeed(setSeedData) {
        const mnemonic = bip39.generateMnemonic();
        const seed = await bip39.mnemonicToSeed(mnemonic);
        const seedHex = seed.slice(0, 32).toString("hex");
        const derivedSeed = this.deriveSeed(seedHex);
        const keyPair = nacl.sign.keyPair.fromSeed(derivedSeed);
        const address = await agent.provider.client.findAccountAddressWithPublicKey(bs58.encode(keyPair.publicKey))

        const seedData = {
            pubKey: bs58.encode(keyPair.publicKey),
            privateKey: bs58.encode(keyPair.secretKey),
            seed: mnemonic,
            address: address
        }

        if (setSeedData) {
            setSeedData(seedData);
        } else {
            return seedData;
        }
    }

    async registerAccount(ownerPrivate) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.registering"))
        await agent.provider.client.sendMessage({
            transaction_name: 'initializeTransaction',
            params: {
                secret: ownerPrivate,
                op_key: InteractionStore.checkInteraction().op_key,
                scopes: InteractionStore.checkInteraction().params.scope,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: (error, success) => {
                if (success) {
                    this.handleSuccess(LangStore.getInstance().t("authorization.notifications.registered"));
                    InteractionStore.setTransactionStatus(null);
                } else {
                    this.handleError(error.description)
                    InteractionStore.setTransactionStatus(null);
                }
            }
        });
    }

    async loginWithNewAccount(pubKey) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.logging.in"));
        const accountAddress = await agent.provider.client.findAccountAddressWithPublicKey(pubKey);
        await this.finishInteraction(InteractionStore.checkInteraction().id, {
            login: accountAddress,
            mergeWithLastSubmission: false,
            setSessionType: "operational"
        })
        InteractionStore.setCustomStage(null);
        InteractionStore.setTransactionStatus(null);
        InteractionStore.deleteCustomHistory();
    }

    async selectAccount(session) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.selecting"));

        if (session.type === "ephemeral" && !InteractionStore.checkInteraction().params.possible_ephemeral) {
            //show seed phrase
            InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.init"));
            try {
                await this.initalizeEphemeral(session.op_key)
                InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.finishing"));
                await this.finishInteraction(InteractionStore.checkInteraction().id, {
                    mergeWithLastSubmission: false,
                    select_account: {
                        account: session.account,
                        op_key: session.op_key,
                    },
                    setSessionType: "owner"
                });
            } catch (e) {
                this.handleError(e);
                InteractionStore.setTransactionStatus(null)
            }
            InteractionStore.setTransactionStatus(null)
        } else {
            try {
                await this.finishInteraction(InteractionStore.checkInteraction().id, {
                    mergeWithLastSubmission: false,
                    select_account: {
                        account: session.account,
                        op_key: session.op_key
                    }
                });
                if (InteractionStore.checkInteractionStage() !== "select_account") {
                    InteractionStore.setTransactionStatus(null)
                }
            } catch (e) {
                this.handleError(e)
                InteractionStore.setTransactionStatus(null)
            }
        }
    }

    async addAccountByOwnerKey(ownerKey, account, userData, setUserData) {
        try {
            const accountData = await agent.provider.client.getAccountData(account);
            if (accountData.owner_keys.includes(userData.owner_key)) {
                if (!userData.addresses.includes(account)) {
                    setUserData((prevData) => {
                        return {
                            ...prevData,
                            ...prevData.addresses.push(account)
                        }
                    });
                    this.handleSuccess(LangStore.getInstance().t("authorization.notifications.added"));
                    InteractionStore.setCustomStage("seed_related_accounts");
                } else {
                    throw new Error("Account already exists in Related Accounts.")
                }
            } else {
                throw new Error("Account is not connected to specified seed phrase or doesn't exist.")
            }
        } catch (e) {
            this.handleError(e)
        }
    }

    async createEphemeralAccount() {
        const accountAddress = await agent.provider.client.findAccountAddressWithPublicKey(InteractionStore.checkInteraction().op_key);

        await this.finishInteraction(InteractionStore.checkInteraction().id, {
            mergeWithLastSubmission: false,
            login: accountAddress,
            consent: true
        })

    }

    checkIDBCompatibility() {
        if (InteractionStore.checkInteraction().params && InteractionStore.checkInteraction().params.idb_supported === false && !InteractionStore.checkAuthError()) {
            InteractionStore.setCustomStage("idb_error");
        }
    }

    async empowerWithSeed(seedPhrase, session) {
        try {
            const accountData = await this.validateSeed(seedPhrase);
            if (accountData) {
                InteractionStore.setTransactionStatus(LangStore.getInstance().t("authorization.transaction.statuses.extending.scopes"));

                console.log("trans data: ", {secretOperationalOrOwner: accountData.owner_private,
                    account: session.account,
                    op_key: session.op_key,
                    scopes: InteractionStore.checkInteraction().empowerment,
                    transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,})
                await agent.provider.client.sendMessage({
                    transaction_name: 'extendOperationalScopesTransaction',
                    params: {
                        secretOperationalOrOwner: accountData.owner_private,
                        account: session.account,
                        op_key: session.op_key,
                        scopes: InteractionStore.checkInteraction().empowerment,
                        transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
                    },

                    transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
                    csrf_token: InteractionStore.checkInteraction().params.csrf_token,

                    cb: (error, success) => {
                        if (success) {
                            this.handleSuccess(LangStore.getInstance().t("authorization.notifications.extended.scopes"));
                        } else {
                            this.handleError(error);
                            InteractionStore.setTransactionStatus(null);
                        }
                    }
                });
            }
        } catch (e) {
            InteractionStore.setTransactionStatus(null);
            this.handleError(e);
        }
    }

    async getUsersAccounts () {
        const accounts = await agent.getAccounts();
        console.log("users accounts: ", accounts);
        return accounts;
    }

    async getHistory(address, page_number = 1) {
        try {
            if (!process.env.REACT_APP_HISTORY_HOST) {
                console.log('history host', process.env.REACT_APP_HISTORY_HOST);
                return null;
            }
            const response = await fetch(`${process.env.REACT_APP_HISTORY_HOST}/transactions/${address}?page_number=${page_number}`);
            return await response.json();
        } catch (error) {
            return null;
        };
    }

    async fetchAccountData(nativeAddress) {
        try {
            InteractionStore.setTransactionStatus(LangStore.getInstance().t("account.info.transaction.statuses.fetching.data"))
            if (nativeAddress) {
                // get evm address
                const evmAddress = agent.provider.client.accountDataConstructor.accountPublicKeyToEVMAddress(nativeAddress);

                // get readable evm address
                const readableEvmAddress = agent.provider.client.accountDataConstructor.accountEvmAddressToReadable(evmAddress);

                // fetch last transactions
                const transactions = await this.getHistory(nativeAddress);

                // fetch balance
                const balance = await this.getAccountBalance(nativeAddress, evmAddress);

                // fetch tokens
                const response = await fetch(`https://evmexplorer.velas.com/api?module=account&action=tokenlist&address=${evmAddress}`)
                    .then(response => response.json())
                    .then(tokenData => {
                        if (tokenData.message === "No tokens found") {
                            console.log("No tokens found.")
                            return null
                        } else {
                            console.log("tokenData", tokenData);
                            return tokenData;
                        }
                    })
                InteractionStore.setTransactionStatus(null)

                return {evmAddress, tokenData: response, transactions, balance, readableEvmAddress}
            } else {
                InteractionStore.setTransactionStatus(null);
                throw new Error("userAccount param is required.")
            }
        } catch (e) {
            this.handleError(e);
            return e.message || e;
        }
    }

    async authorizeToAccountInfo() {
        const response = await fetch(`${process.env.REACT_APP_SPONSOR_HOST}/csrf`);
        const { token } = await response.json();
        console.log("token: ", token);
        const { interaction } = await agent.authorizeAccountManagement({
            token,
            transactions_sponsor_api_host: process.env.REACT_APP_SPONSOR_HOST,
            transactions_sponsor_pub_key: process.env.REACT_APP_SPONSOR_PUB_KEY
        });
        console.log("interaction", interaction)
        InteractionStore.setInteractionData(interaction);

        return interaction;
    }

    async getAccountsData(accounts) {
        return accounts.map(async (account) => {
            account.accountData = await this.fetchAccountData(account.account);
        })
    }

    async backupEphemeralAccount(account, interaction, newPrivateKey) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("account.info.transaction.statuses.backing.up"));
        const oldPrivateKey = account.op_key;
        console.log("sending backup transaction...")
        await agent.provider.client.sendMessage({
            transaction_name: 'backupEphemeral',
            params: {
                secret: oldPrivateKey,
                secret_new: newPrivateKey,
                op_key: interaction.op_key,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: async (error, success) => {
                if (success) {
                    await this.finishInteraction(interaction.id, {
                        select_account: {
                            account: account.account,
                            op_key: oldPrivateKey,
                        },
                        replaceOperational: interaction.op_key,
                        setSessionType: "operational",
                        mergeWithLastSubmission: false
                    });
                    await this.authorizeToAccountInfo();
                    AccountStore.reset();
                    AccountStore.setCurrentStage("accounts");
                    InteractionStore.setTransactionStatus(null);
                    this.handleSuccess(LangStore.getInstance().t("authorization.notifications.backed_up"));
                } else {
                    InteractionStore.setTransactionStatus(null);
                    this.handleError(error)
                }
            }
        });
    }

    async backupOwnerAccount(account, interaction, newPrivateKey) {
        InteractionStore.setTransactionStatus(LangStore.getInstance().t("account.info.transaction.statuses.backing.up"));
        const oldPrivateKey = account.op_key;
        console.log("sending backup transaction...")
        await agent.provider.client.sendMessage({
            transaction_name: 'backupOwner',
            params: {
                secret: oldPrivateKey,
                secret_new: newPrivateKey,
                op_key: interaction.op_key,
                transactions_sponsor_pub_key: InteractionStore.checkInteraction().params.transactions_sponsor_pub_key,
            },

            transactions_sponsor_api_host: InteractionStore.checkInteraction().params.transactions_sponsor_api_host,
            csrf_token: InteractionStore.checkInteraction().params.csrf_token,

            cb: async (error, success) => {
                if (success) {
                    await this.finishInteraction(interaction.id, {
                        select_account: {
                            account: account.account,
                            op_key: oldPrivateKey,
                        },
                        replaceOperational: interaction.op_key,
                        setSessionType: "operational",
                        mergeWithLastSubmission: false
                    });
                    await this.authorizeToAccountInfo();
                    AccountStore.reset();
                    AccountStore.setCurrentStage("accounts");
                    InteractionStore.setTransactionStatus(null);
                    this.handleSuccess(LangStore.getInstance().t("authorization.notifications.backed_up"));
                } else {
                    InteractionStore.setTransactionStatus(null);
                    this.handleError(error)
                }
            }
        });
    }

    async terminateAuthorization(oldInteraction, op_key, authorization) {
        try {
            const { interaction } = await agent.logout(oldInteraction.id, op_key, authorization.client_info.client_id);
            if (!interaction) throw new Error("Interaction not found after logout");
            InteractionStore.setInteractionData(interaction);
            AccountStore.setActiveAccountSessions(AccountStore.getActiveAccountSessions().filter(session=>(
                session.client_id !== authorization.client_id
            )));
        } catch (e) {
            this.handleError(e);
        }
    }

    async getAccountBalance(nativeAddress, evmAddress) {
        try {
            const publicKey = new agent.provider.client.web3.PublicKey(nativeAddress);
            const nativeBalanceRaw = await agent.provider.client.connection.getBalance(publicKey);
            // eslint-disable-next-line no-undef
            const nativeBalanceBigNum = BigInt(nativeBalanceRaw) / BigInt(10 ** 9);
            const web3 = new Web3(process.env.REACT_APP_EVM_EXPLORER_RPC);
            const evmBalanceRaw = await web3.eth.getBalance(evmAddress);
            // eslint-disable-next-line no-undef
            const evmBalanceBigNum = BigInt(evmBalanceRaw) / BigInt(10 ** 18);

            const result = nativeBalanceBigNum + evmBalanceBigNum;
            return result.toString();
        } catch (e) {
            this.handleError(e)
        }
    }
}

export default new FunctionInteractionStore();