import './index.sass'
import {useState} from 'react';
import StoreContext from "../../../../store/RootStore";
import {observer} from "mobx-react";

import './index.sass'
import {ReactComponent as BackArrow} from "../../../../assets/images/box-header-arrow.svg";

function BoxHeader({stage}) {
    const { LangStore, AccountStore, InteractionStore } = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.

    function renderHeaderContent() {
        switch (stage) {
            case "home":
            case "sessions":
            case "tokens":
                return (
                    <>
                        <div className="box-nav-item">
                            <div className="box-nav-item-network-icon"/>
                            <span
                                className="box-nav-item-text">{process.env.REACT_APP_ENV.charAt(0).toUpperCase() + process.env.REACT_APP_ENV.slice(1)} {LangStore.getInstance().t("account.info.header.network")}</span>
                        </div>
                        {AccountStore.getAccounts().length > 0 ?
                            <div className="box-nav-item" onClick={() => {
                                AccountStore.setCurrentStage("accounts")
                            }}>
                                <span className="box-nav-item-text box-nav-item-text-clickable">{LangStore.getInstance().t("account.info.header.accounts")}</span>
                            </div>
                        : ""}
                    </>
                )

            case "backup_account":
            case "restore_account":
                if (InteractionStore.checkCustomStage() && InteractionStore.checkCustomStage() !== "idb_error" && !InteractionStore.checkTransactionStatus()) {
                    return (
                        <div className="box-nav-back" onClick={() => InteractionStore.customGoBack()}>
                            <BackArrow className="box-nav-arrow"/>
                            <span className="box-nav-text">{LangStore.getInstance().t("authorization.buttons.back")}</span>
                        </div>
                    )
                } else {
                    return;
                }

            case "accounts":
                if (AccountStore.getStageHistory().length > 0) {
                    return (
                        <div className="box-nav-back" onClick={() => AccountStore.goBack()}>
                            <BackArrow className="box-nav-arrow"/>
                            <span className="box-nav-text">{LangStore.getInstance().t("authorization.buttons.back")}</span>
                        </div>
                    )
                } else {
                    return;
                }

            // implement later
            // case "tokens":
            //     return (
            //         <>
            //             <div className="box-nav-item">
            //                 <div className="box-nav-item-network-icon"/>
            //                 <span className="box-nav-item-text">{process.env.REACT_APP_ENV.charAt(0).toUpperCase() + process.env.REACT_APP_ENV.slice(1)} {LangStore.getInstance().t("account.info.header.network")}</span>
            //             </div>
            //             <div className="box-nav-item-wrapper">
            //                 <div className="box-nav-item">
            //                     <span className="box-nav-item-text">{LangStore.getInstance().t("account.info.header.add.token")}</span>
            //                     <DownArrow className="box-nav-item-arrow"/>
            //                 </div>
            //                 <div className="box-nav-item" onClick={()=>{
            //                     AccountStore.reset();
            //                     AccountStore.setCurrentStage("accounts");
            //                 }}>
            //                     <span className="box-nav-item-text box-nav-item-text-clickable">{LangStore.getInstance().t("account.info.header.accounts")}</span>
            //                     <DownArrow className="box-nav-item-arrow"/>
            //                 </div>
            //             </div>
            //         </>
            //     )

            default:
                return;
        }
    }

    return (
        <div className="box-nav-wrapper">
            <div className="box-nav">
                { renderHeaderContent() }
            </div>
        </div>
    )
}

export default observer(BoxHeader);
