import {ReactComponent as TextImage404} from '../../assets/images/404-text-image.svg'
import {ReactComponent as AreYouLost} from '../../assets/images/404-are-you-lost.svg'
import {ReactComponent as Sorry} from '../../assets/images/404-sorry.svg'
import {ReactComponent as LogoWhite} from '../../assets/images/logo-white.svg'

import './index.sass'

export default function Page404() {
    return (
        <main className="container-404">
            <LogoWhite className="logo-white-404"/>
            <AreYouLost className="title-404"/>
            <TextImage404 className="text-image-404"/>
            <Sorry className="text-404"/>
        </main>
    )
}