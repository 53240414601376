import * as velasWeb3 from '@velas/web3';
import Agent from "@velas/account-agent";
import * as jdenticon from 'jdenticon';

//custom functions to enable usage of LocalStorage and broadcast for the Agent library
import StorageHandler from './storageHandler'
import KeyStorageHandler from "./keyStorageHandler";
import broadcastTransactionHendler from "./broadcast";

export const agent = new Agent({
    client_host: process.env.REACT_APP_NODE_HOST,
    client_account_contract: process.env.REACT_APP_ACCOUNT_CONTRACT,
    client_provider: velasWeb3,

    StorageHandler,
    KeyStorageHandler,
    broadcastTransactionHendler,

    UserInfoIconHandler: jdenticon.toSvg,
});