import './index.sass'
import ReactDOM from "react-dom";

export default function Modal({opened, setOpened, header, headerType, text, children}) {
    return (
        <>
            {ReactDOM.createPortal(<div className={`modal-overlay ${opened ? "modal-overlay-opened" : ""}`}
             onClick={setOpened ? (ev)=>{
                 ev.stopPropagation();
                 setOpened(false);
             } : null}>
                <div className="modal-content">
                    <h4 className={`modal-header ${headerType ? " modal-header-" + headerType : ""}`}>{header}</h4>
                    <div className="modal-content-wrapper">
                        <p className="modal-text">{text}</p>
                        {children}
                    </div>
                </div>
            </div>, document.body)}
        </>
    )
}