import './index.sass';
import {observer} from "mobx-react";
import StoreContext from "../../../../store/RootStore";
import {useState} from "react";
import NotchButton from "../../../notchButton";
import FunctionStore from "../../../../store/FunctionStore";

function SeedAddAccount({userData, setUserData}) {
    const {InteractionStore, LangStore} = StoreContext();
    const [warning, setWarning] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const [userAddress, setUserAddress] = useState("");

    function validateUserAddress(addr, userData, setUserData) {
        try {
            if ( addr.length < 32 || addr.length > 44) {
                throw new Error("Incorrect address length.");
            } else if(addr.includes("0") || addr.includes("O") || addr.includes("I") || addr.includes("l")){
                throw new Error("Incorrect address type.");
            } else {
                console.log("validation success");
                setWarning(null);
                console.log("trying to add account by owner key...");
                FunctionStore.addAccountByOwnerKey(userData.owner_key, addr, userData, setUserData);
            }
        } catch (e) {
            FunctionStore.handleError(e)
        }
    }

    return (
        <>
            <div className="seed-add-account">
                <label className="seed-add-input-label">
                    <span
                        className="seed-add-input-title">{LangStore.getInstance().t("authorization.stages.seed_add.account")}</span>
                    <input type="text" className="seed-add-input"
                           value={userAddress}
                           onChange={(ev)=>setUserAddress(ev.target.value)}
                           placeholder={LangStore.getInstance().t("authorization.stages.seed_add.enter")}/>
                </label>
                <p className={`seed-add-description ${warning ? "seed-add-description-smargin" : ""}`}>{LangStore.getInstance().t("authorization.stages.seed_add.description")}</p>
            </div>
            <hr className="box-black-hr"/>
            <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.confirm")} onClick={()=> {
                validateUserAddress(userAddress, userData, setUserData);
            }} disabled={InteractionStore.checkTransactionStatus()} color="black"/>
        </>
    )
}

export default observer(SeedAddAccount)