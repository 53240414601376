import { makeAutoObservable } from "mobx";

class AccountStore {

    constructor() {
        makeAutoObservable(this);
    }

    activeAccount = null;
    stage = null;
    previousStages = [];
    accounts = [];

    activeAccountData = {
        evmAddress: null,
        readableEvmAddress: null,
        nativeAddress: null,
        type: null,
        balance: null,
        tokens: [],
        sessions: [],
        transactions: null
    }

    // getters:

    getCurrentStage() {
        return this.stage;
    }

    getStageHistory() {
        return this.previousStages;
    }

    getAccounts() {
        return this.accounts;
    }

    getSelectedAccount() {
        return this.selectedAccount;
    }

    getActiveAccount() {
        return this.activeAccount;
    }

    getActiveAccountData(){
        return this.activeAccountData;
    }

    getActiveAccountSessions() {
        return this.activeAccountData.sessions;
    }

    getActiveAccountTokens() {
        return this.activeAccountData.tokens;
    }

    getActiveAccountBalance() {
        return this.activeAccountData.balance;
    }

    getActiveAccountTransactions() {
        return this.activeAccountData.transactions;
    }

    // setters:

    setCurrentStage(stage) {
        if (this.stage) {
            this.previousStages.push(this.stage);
        }
        this.stage = stage;
    }

    setAccounts(accounts) {
        this.accounts = accounts;
    }

    setSelectedAccount (account) {
        this.selectedAccount = account;
    }

    setTransactions(transactions) {
        this.activeAccountData.transactions = transactions;
    }

    setActiveAccount(account) {
        this.activeAccount = account;
        this.activeAccountData.evmAddress = account.accountData.evmAddress;
        this.activeAccountData.readableEvmAddress = account.accountData.readableEvmAddress
        this.activeAccountData.nativeAddress = account.accountData.nativeAddress;
        this.activeAccountData.balance = account.accountData.balance;
        this.activeAccountData.tokens = account.accountData.tokenData || [];
        this.activeAccountData.type = account.type;
        this.activeAccountData.transactions = account.accountData.transactions || null;
        this.activeAccountData.sessions = account.authorizations ? Object.values(account.authorizations) : [];
        console.log("active account", this.activeAccount);
    }

    setActiveAccountSessions(sessions) {
        this.activeAccountData.sessions = sessions;
    }

    setActiveAccountBalance(balance) {
        this.activeAccountData.balance = balance;
    }

    goBack() {
        if (this.previousStages.length > 0) {
            this.stage = this.previousStages[this.previousStages.length-1]
            this.previousStages.pop();
        }
    }

    reset() {
        this.selectedAccount = null;
        this.accounts = [];
        this.activeAccount = null;
        this.previousStages = [];
        this.activeAccountData = {
            evmAddress: null,
            nativeAddress: null,
            type: null,
            balance: null,
            tokens: [],
            sessions: [],
            transactions: null
        }
        if (window.location.pathname !== "/") {
            window.history.replaceState(null, null, "/");
        }
    }
}

export default new AccountStore();