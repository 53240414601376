import './index.sass';
import {observer} from "mobx-react";
import NotchBlock from "../notchBlock";
import BoxFooter from "../auth/box/boxFooter";

function Box({children}) {
    return (
        <div className="account-info-box-wrapper">
            <NotchBlock type="main">
                <div className="box-shadow"/>
                <div className="box-content">
                    {children}
                </div>
            </NotchBlock>
            <BoxFooter/>
        </div>
    )
}

export default observer(Box);