import DocumentContainer from "../../components/documentContainer";
import ReactMarkdown from 'react-markdown'
import {useEffect, useState} from "react";
import markdown from '../../assets/text/terms&privacy/privacy.md';
import remarkGfm from 'remark-gfm'
import remarkHeadingID from 'remark-heading-id'


export default function Privacy() {
    const [content, setContent] = useState(null)
    useEffect(()=>{
        fetch(markdown).then(res => res.text()).then(text => setContent(text));
    },[])

    return (
        <DocumentContainer>
            {content ? <ReactMarkdown className="document-content-md" children={content} remarkPlugins={[remarkGfm, remarkHeadingID]}/> : ""}
        </DocumentContainer>
    )
}

