import './index.sass'
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import StoreContext from "../../../../store/RootStore";
import Seed from "../../seed";
import Preloader from "../../../preloader";
import {agent} from "../../../../functions/agent";

// NOT IMPLEMENTED YET!

function InteractionBackup() {
    const [loading, setLoading] = useState(false);
    const [seedData, setSeedData] = useState({});
    const {InteractionStore, FunctionStore} = StoreContext();

    useEffect(()=>{
        InteractionStore.setCustomStage("seed_show");
        FunctionStore.generateSeed(setSeedData);
    }, [setSeedData, FunctionStore, InteractionStore])

    useEffect(() => {
        async function replaceOwner() {
            setLoading(true);
            await agent.provider.client.replaceOwnerTransaction({
                secret: InteractionStore.checkInteraction().backup_seed.ephemeral_session, // check this
                secret_new: seedData.privateKey,
                account: InteractionStore.checkInteraction().backup_seed.account //added account to interaction stage seed_backup
            })
        }

        if (InteractionStore.customStage === "replace_owner") {
            replaceOwner();
        }
    }, [InteractionStore.customStage, InteractionStore, seedData]);

    return (
        loading ? <Preloader/> : <Seed phrase={seedData.seed  ? seedData.seed : ""} stageAfterVerify="replace_owner"/>
    )
}

export default observer(InteractionBackup)