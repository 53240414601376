import Preloader from '../../preloader';
import loadbar from '../../../assets/gifs/auth-box-loadbar.gif'
import './index.sass';
import InteractionLogin from "../interactionLogin";
import StoreContext from "../../../store/RootStore";
import {observer} from "mobx-react";
import {useState} from "react";
import NotchBlock from "../../notchBlock";

function Box({intStage}) {
    const {LangStore, InteractionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const currentStage = InteractionStore.checkInteractionStage();
    const customStage = InteractionStore.checkCustomStage();

    function renderHeaderContent(stage, customStage) {
        if ((stage === "" || stage) && !customStage) {
            switch (stage) {
                case "":
                case "login":
                case "select_account":
                    return <>
                        <h1 className="box-header-title">{ stage === "select_account" ? LangStore.getInstance().t("authorization.stages.select_account.header") : LangStore.getInstance().t("authorization.stages.login.header") }</h1>
                        <span className="box-header-link-text">{LangStore.getInstance().t("authorization.stages.login.to")}<a
                                href={stage ? InteractionStore.checkInteraction().client.redirect_uri : ""}
                                target="_blank" rel="noreferrer"
                                className="box-header-link">{stage ? " " + InteractionStore.checkInteraction().client.client_name : ""}</a></span>
                        {stage === "" ? <img src={loadbar} alt="loadbar" className="box-header-loadbar"/> :
                            <hr className="box-black-hr box-black-hr-top"/>}
                    </>
                case "consent":
                case "empowerment":
                case "merge":
                    return <>
                        <h1 className="box-header-title">{LangStore.getInstance().t(`authorization.stages.${stage}.title`)}</h1>
                        <hr className="box-black-hr box-black-hr-top"/>
                    </>
                default:
                    return
            }
        } else if(stage && customStage) {
            switch (customStage) {
                case "seed_restore":
                case "seed_add":
                case "seed_related_accounts":
                case "seed_show":
                case "seed_verify":
                case "express_sign_up":
                case "sign_up":
                case "sign_up_qr":
                case "auth_error":
                case "idb_error":
                case "empowerment_seed":
                    return <>
                        <h1 className="box-header-title">{LangStore.getInstance().t(`authorization.stages.${customStage}.title`)}</h1>
                        <hr className="box-black-hr box-black-hr-top"/>
                    </>
                case "login":
                    return <>
                        <h1 className="box-header-title">{LangStore.getInstance().t("authorization.stages.login.header")}</h1>
                        <span className="box-header-link-text">{LangStore.getInstance().t("authorization.stages.login.to")}
                            <a
                                href={stage ? InteractionStore.checkInteraction().client.redirect_uri : ""}
                                target="_blank" rel="noreferrer"
                                className="box-header-link">{stage ? " " + InteractionStore.checkInteraction().client.client_name : ""}</a></span>
                        <hr className="box-black-hr box-black-hr-top"/>
                    </>
                default:
                    return <>
                        <h1 className="box-header-title">{LangStore.getInstance().t(`authorization.stages.select_account.title`)}</h1>
                        <hr className="box-black-hr box-black-hr-top"/>
                    </>
            }
        }

    }

    return (
        <NotchBlock type="main">
            <div className="box-shadow"/>
            <div className="box-header">
                {renderHeaderContent(currentStage,customStage)}
            </div>
            <div className="box-content">
                {intStage ? <InteractionLogin/> :<Preloader/>}
            </div>
        </NotchBlock>
    )
}

export default observer(Box);