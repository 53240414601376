import {useState, useEffect} from 'react';
import {ReactComponent as LogoWhite} from '../../assets/images/logo-white.svg'
import {ReactComponent as LogoGlitch} from '../../assets/images/logo-glitch.svg'
import {observer} from 'mobx-react';
import {useNavigate, useLocation, useMatch} from "react-router-dom";
import AccountTicker from '../../components/auth/accountTicker';
import Box from '../../components/auth/box';
import BoxFooter from "../../components/auth/box/boxFooter";
import BoxHeader from "../../components/auth/box/boxHeader";
import StoreContext from '../../store/RootStore';
import Notification from "../../components/notification";
import NotchButton from "../../components/notchButton";
import ReactTooltip from "react-tooltip";

import './index.sass'

function AuthorizationPage() {

    //Constants:
    const {InteractionStore, FunctionStore, LangStore} = StoreContext();
    const interactionStage = InteractionStore.checkInteractionStage();
    const routerNav = useNavigate();
    const currentLocation = useLocation();
    const id = useMatch('/authorize/:id');
    const transactionStatus = InteractionStore.checkTransactionStatus();

    //Local States:
    const [token, setToken] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.

    //Lifecycle interactions:
    useEffect(() => {
        // First we check whether we have an address that doesn't have a valid id
        if (typeof (currentLocation) !== "undefined" && !id) {
            // If it doesn't, we parse the auth token and send it to the resolveToken function, which replaces the location, thus useffect runs again.
            const tempToken = currentLocation.search.replace('?', '');
            setToken(tempToken.substring(tempToken.indexOf('=') + 1));
            token && FunctionStore.resolveToken(token, routerNav)
        } else if (id && !InteractionStore.checkInteraction().id) {
            // If it does, we get the interaction by its ID and update the store by using the handleInteraction function
            console.log("got id", id.params.id)
            FunctionStore.handleInteraction(id.params.id);
        }
    }, [currentLocation, token, FunctionStore, InteractionStore, id, routerNav]);

    useEffect(() => {
        console.log("Trans status: ", transactionStatus)
        if (transactionStatus && transactionStatus !== LangStore.getInstance().t("authorization.transaction.statuses.logging.in")
            && transactionStatus !== LangStore.getInstance().t("authorization.transaction.statuses.extending.scopes")
            && transactionStatus !== LangStore.getInstance().t("authorization.transaction.statuses.selecting")
            && transactionStatus !== LangStore.getInstance().t("authorization.transaction.statuses.approving")
            && transactionStatus !== LangStore.getInstance().t("authorization.transaction.statuses.merging")
            && transactionStatus !== "auth_error") {
            window.onbeforeunload = function () {
                return ""
            }
        } else {
            window.onbeforeunload = null;
        }
    }, [transactionStatus, LangStore])

    return (
        <div className="auth-page">
            <Notification/>
            <AccountTicker/>
            <LogoWhite className="auth-header-logo"/>
            <NotchButton type="button" color="white" value="Beta" containerClassName="auth-page-beta-warning"
                         buttonClassName="auth-page-beta-warning-button" data-for="auth-page-beta-warning-tooltip"
                         data-tip={LangStore.getInstance().t("authorization.beta.warning")}/>
            <ReactTooltip id="auth-page-beta-warning-tooltip" multiline={true} place="bottom" effect="solid"
                          type="warning" className="auth-page-beta-warning-info"/>
            <main className="auth-content">
                <div className="auth-box-wrapper">
                    <BoxHeader stage={interactionStage} customStage={InteractionStore.checkCustomStage()}
                               cancel={() => FunctionStore.cancelInteraction(InteractionStore.checkInteraction().id)}/>
                    <Box intStage={interactionStage}/>
                    <BoxFooter/>
                </div>
                <LogoGlitch className="auth-logo-glitch"/>
            </main>
        </div>
    )
}

export default observer(AuthorizationPage);