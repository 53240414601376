import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import App from './App'
import Page404 from "./pages/404";
import IFramePage from "./pages/iframe";
import AuthorizationPage from "./pages/authPage";
import TokenToOperational from "./pages/tokenToOperational";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Access from "./pages/access";
import RedirectComponent from "./components/userInfoRedirect"
import Preloader from './pages/preloader';

export default function AppRoutes() {
    return (
        <App>
            <Router>
                <Routes>
                    <Route path="/authorize" element={<AuthorizationPage/>}/>
                    <Route path="/authorize/:id" element={<AuthorizationPage/>}/>
                    <Route path="/iframe" element={<IFramePage/>}/>
                    <Route path="/mobile" element={<TokenToOperational/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/access" element={<Access/>}/>
                    <Route path="/preloader" element={<Preloader/>}/>
                    <Route path="/" element={<RedirectComponent/>}/>
                    <Route path="/account/:id" element={<RedirectComponent/>}/>
                    <Route path="*" element={<Page404/>}/>
                </Routes>
            </Router>
        </App>
    );
}
