import './index.sass'
import {useState} from "react";
import {Collapse} from 'react-collapse';
import {ReactComponent as DownArrow} from '../../../../assets/images/black-down-arrow.svg'
import {ReactComponent as CopyIcon} from '../../../../assets/images/copy-icon.svg'
import StoreContext from "../../../../store/RootStore";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import Jdenticon from 'react-jdenticon';
import {agent} from '../../../../functions/agent';

export default function AccountDropDown({
                                            session,
                                            account,
                                            onClick,
                                            disabled,
                                            copyAccountAddressOnClick,
                                            addressObject,
                                            additionalClassName
                                        }) {
    const [opened, setOpened] = useState(false);
    const {LangStore, FunctionStore} = StoreContext();

    async function copyAccountAddress(event, address) {
        event.preventDefault();
        event.stopPropagation();
        await navigator.clipboard.writeText(address);
        FunctionStore.handleSuccess(LangStore.getInstance().t("authorization.notifications.addr.copied"))
    };

    let evmAccount = '-';
    let uniqueName = '-';

    if (session && session.account) {
        evmAccount = agent.provider.client.accountDataConstructor.accountPublicKeyToEVMAddress(session.account);
        uniqueName = agent.provider.client.accountDataConstructor.accountEvmAddressToReadable(evmAccount);
    } else if (account) {
        evmAccount = agent.provider.client.accountDataConstructor.accountPublicKeyToEVMAddress(account);
        uniqueName = agent.provider.client.accountDataConstructor.accountEvmAddressToReadable(evmAccount);
    }
    ;

    return (
        <>
            {session && !account && !onClick ? <>
                <div className={`account-dropdown-wrapper ${additionalClassName ? additionalClassName : ""}`}>
                    <div
                        className={opened ? "account-dropdown-border-container-active account-dropdown-border-container" : "account-dropdown-border-container"}>
                        <div className={opened ? "account-dropdown account-dropdown-active" : "account-dropdown"}>
                            <Jdenticon size="38" value={evmAccount} className="account-dropdown-icon"/>
                            <span className="account-dropdown-address"
                                  onClick={() => {
                                      FunctionStore.selectAccount(session);
                                  }}>{uniqueName}
                            </span>
                            <div
                                className={opened ? "account-dropdown-connections account-dropdown-connections-active" : "account-dropdown-connections"}
                                onClick={() => setOpened(!opened)}
                                data-for="connections-number"
                                data-tip={LangStore.getInstance().t("authorization.stages.select_account.tooltip")}
                            >
                                <span
                                    className="account-dropdown-connections-number">{session.authorizations ? session.count : 0}</span>
                                <DownArrow className="account-dropdown-connections-arrow"/>
                            </div>
                        </div>
                    </div>
                    <Collapse isOpened={opened}>
                        <div className="account-dropdown-content-wrapper">
                            <div className="account-dropdown-content">
                                <ul className="account-dropdown-content-list">
                                    <li className="account-dropdown-content-list-item">
                                        <span
                                            className="account-dropdown-content-list-item-name">{LangStore.getInstance().t("authorization.stages.select_account.address")} (EVM)</span>
                                        <span
                                            className="account-dropdown-content-list-item-value account-dropdown-content-list-item-address"
                                            onClick={(e) => copyAccountAddress(e, evmAccount)}>{evmAccount.slice(0, evmAccount.length / 4).concat("........").concat(evmAccount.slice(evmAccount.length - evmAccount.length / 4, evmAccount.length))}           
                                        </span>
                                    </li>
                                    <li className="account-dropdown-content-list-item">
                                        <span
                                            className="account-dropdown-content-list-item-name">{LangStore.getInstance().t("authorization.stages.select_account.address")} (NATIVE)</span>
                                        <span
                                            className="account-dropdown-content-list-item-value account-dropdown-content-list-item-address"
                                            onClick={(e) => copyAccountAddress(e, session.account)}>{session.account.slice(0, session.account.length / 4).concat("........").concat(session.account.slice(session.account.length - session.account.length / 4, session.account.length))}           
                                        </span>
                                    </li>
                                    <li className="account-dropdown-content-list-item">
                                        <span
                                            className="account-dropdown-content-list-item-name">{LangStore.getInstance().t("authorization.stages.select_account.operational")}</span>
                                        <span
                                            className="account-dropdown-content-list-item-value">{session.op_key.slice(0, session.op_key.length / 4).concat("........").concat(session.op_key.slice(session.op_key.length - session.op_key.length / 4, session.op_key.length))}</span>
                                    </li>
                                    <li className="account-dropdown-content-list-item">
                                        <span
                                            className="account-dropdown-content-list-item-name">{LangStore.getInstance().t("authorization.stages.select_account.connections")}</span>
                                        <span
                                            className="account-dropdown-content-list-item-value">{session.authorizations ? session.count : 0}</span>
                                    </li>
                                </ul>
                                <button className="account-dropdown-content-terminate"
                                        onClick={() => FunctionStore.terminateSession(session)}>{LangStore.getInstance().t("authorization.buttons.terminate")}
                                </button>
                            </div>
                        </div>
                    </Collapse>
                </div>
                {/*A portal is needed here to render the tooltip on top of all of the components, somehow the content is being cut despite overflow:visible*/}
                {ReactDOM.createPortal(<ReactTooltip id="connections-number" disable={!opened} multiline={true}
                                                     className="account-dropdown-connections-number-tooltip"/>, document.getElementById("root"))}
            </> : !session && account && (onClick || copyAccountAddressOnClick) ?
                <>
                    <div className={`account-dropdown-wrapper ${additionalClassName ? additionalClassName : ""}`}>
                        <div
                            className="account-dropdown-border-container">
                            <div className="account-dropdown">
                                <Jdenticon size="38" value={evmAccount} className="account-dropdown-icon"/>
                                <span className="account-dropdown-address"
                                      onClick={!disabled && onClick ? () => onClick() : undefined}
                                >
                                        {uniqueName}
                                </span>
                                {copyAccountAddressOnClick ?
                                    <div className="account-dropdown-connections" onClick={async (e) => {
                                        await copyAccountAddress(e, evmAccount);
                                    }}>
                                        <CopyIcon className="account-dropdown-connections-arrow"/>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </>
                : !session && !account && copyAccountAddressOnClick && addressObject ?
                    <div className={`account-dropdown-wrapper ${additionalClassName ? additionalClassName : ""}`}>
                        <div
                            className="account-dropdown-border-container">
                            <div className="account-dropdown">
                                <Jdenticon size="38" value={addressObject.address} className="account-dropdown-icon"/>
                                <div className="account-dropdown-address-wrapper">
                                    <span className="account-dropdown-address-type">{addressObject.type}</span>
                                    <span className="account-dropdown-address"
                                          onClick={!disabled && onClick ? () => onClick() : undefined}
                                    >
                                            {addressObject.addressShort}
                                    </span>
                                </div>

                                <div className="account-dropdown-connections" onClick={!disabled ? async (e) => {
                                    await copyAccountAddress(e, addressObject.address);
                                } : undefined}>
                                    <CopyIcon className="account-dropdown-connections-arrow"/>
                                </div>

                            </div>
                        </div>
                    </div>
                    : <span>Incorrect Input Data</span>
            }

        </>
    )
}