import './index.sass'
import StoreContext from "../../../../store/RootStore";
import {observer} from "mobx-react";
import {agent} from "../../../../functions/agent";
import QRCode from "qrcode.react";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import NotchButton from "../../../notchButton";
import Seed from "../../seed";
import Preloader from "../../../preloader";

function InteractionEmpowerment() {
    const {LangStore, InteractionStore, FunctionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const decodedEmpower = agent.provider.sc.encode(InteractionStore.checkInteraction().empowerment || []);
    const currentStage = InteractionStore.checkInteractionStage();
    const [fetchingData, setFetchingData] = useState(true);

    useEffect(() => {
        // Fetching changes made with the op key to detect login with qr every 4 seconds
        if (currentStage === "empowerment" && fetchingData && InteractionStore.customStage !== "empowerment_seed") {
            const interval = setInterval(() => FunctionStore.fetchScopeChanges(setFetchingData), 4000);
            return () => clearInterval(interval);
        }
    }, [currentStage, fetchingData, FunctionStore, InteractionStore.customStage])

    return (
        InteractionStore.checkCustomStage() === "empowerment_seed" ?
            InteractionStore.checkTransactionStatus() ?
                <div className="accounts-preloader">
                    <Preloader/>
                    <p className="preloader-text">{InteractionStore.checkTransactionStatus()}</p>
                </div> :
                <Seed
                    actionAfterVerify={async (seed) => {
                        await FunctionStore.empowerWithSeed(seed, InteractionStore.checkInteraction().session, InteractionStore.checkInteraction().params.scope);
                        await FunctionStore.finishInteraction(InteractionStore.checkInteraction().id, {
                            mergeWithLastSubmission: false
                        });
                        InteractionStore.setTransactionStatus(null);
                        InteractionStore.setCustomStage(null);
                    }}
                    phraseValidator={async (seed) => await FunctionStore.validateSeed(seed)}
                />
            :
            <>
                <div className="empower-wrapper">
                    <h3 className="empower-title">{LangStore.getInstance().t("authorization.stages.empowerment.description")}</h3>
                    <span className="login-options-text">{LangStore.getInstance().t("authorization.qr")}</span>
                    <QRCode className="empower-qr-code" size={150} value={FunctionStore.getQrDeepLink("empowerment")}/>
                    {isMobile ? <NotchButton type="button" color="white"
                                             onClick={() => window.location.href = FunctionStore.getQrDeepLink("empowerment")}
                                             value={LangStore.getInstance().t("authorization.buttons.open")}/> : ""}
                    <ul className='empower-scopes'>
                        <h4 className="empower-scopes-title">{LangStore.getInstance().t("authorization.stages.empowerment.scopes")}</h4>
                        {decodedEmpower.map((value, index) => {
                            if (value === 'VelasAccountProgram:Transfer') return <li className="empower-scopes-item"
                                                                                     key={index}>Transfer</li>
                            else if (value === 'authorization') return <li className="empower-scopes-item"
                                                                           key={index}>Authorization
                                on {InteractionStore.checkInteraction().client.client_name}</li>
                            else if (value === 'openid') return <li className="empower-scopes-item" key={index}>Account
                                info</li>
                            else return <li className="empower-scopes-item" key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <hr className="box-black-hr"/>
                <NotchButton type="button" color="black"
                             value={LangStore.getInstance().t("authorization.stages.empowerment.alt")} onClick={() => {
                    InteractionStore.setCustomStage("empowerment_seed")
                }}/>
            </>
    )
}

export default observer(InteractionEmpowerment);