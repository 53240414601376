import DocumentContainer from "../../components/documentContainer";

export default function Access() {
	return (
		<DocumentContainer>
			<div className="preloader-wrapper">
				<div className="document-content-md">
						<h2>Privacy settings</h2>
						<p>
							Your browser's privacy settings do not allow access to the cross
							site storage. Please disable "prevent cross site tracking" option
							for current browser.
						</p>
					</div>
			</div>
		</DocumentContainer>
	);
}
