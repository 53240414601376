import {useState} from 'react';
import {ReactComponent as BackArrow} from '../../../../assets/images/box-header-arrow.svg';
import StoreContext from "../../../../store/RootStore";
import {observer} from "mobx-react";

import './index.sass'

function BoxHeader({stage, customStage, cancel}) {
    const {LangStore, InteractionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    function renderBoxNav(stage, customStage) {
        if (InteractionStore.checkTransactionStatus()) {
            return;
        } else if (stage && customStage && customStage !== "idb_error") {
            return (
                <>
                    <div className="box-nav-back" onClick={()=> InteractionStore.customGoBack()}>
                        <BackArrow className="box-nav-arrow"/>
                        <span className="box-nav-text">{LangStore.getInstance().t("authorization.buttons.back")}</span>
                    </div>
                </>
            )
        } else if ((stage && !customStage) || (stage && customStage === "idb_error")) {
            return (
                <div onClick={() => cancel()} className="box-nav-cancel">
                    <div className="box-nav-close">
                        <div className="box-nav-close-line"></div>
                        <div className="box-nav-close-line"></div>
                    </div>
                    <span className="box-nav-text">{LangStore.getInstance().t("authorization.buttons.cancel")}</span>
                </div>
            )
        } else if (!stage && !customStage) {
            return (
                <span className="box-nav-text box-nav-connecting">{LangStore.getInstance().t("authorization.connecting")}</span>
            )
        }
    }
    return (
        <div className="box-nav-wrapper">
            <div className="box-nav">
                {renderBoxNav(stage, customStage)}
            </div>
        </div>
    )
}

export default observer(BoxHeader);
