import './index.sass'
import StoreContext from "../../../../store/RootStore";
import {observer} from "mobx-react";
import {agent} from "../../../../functions/agent";
import NotchButton from "../../../notchButton";
import Preloader from "../../../preloader";
import {useState} from "react";

function InteractionConsent() {
    const {LangStore, InteractionStore, FunctionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const decodedScopes = agent.provider.sc.encode(InteractionStore.checkInteraction().scopes.new_scopes)

    return (InteractionStore.checkTransactionStatus() ?
            <>
                <Preloader/>
                <span className="preloader-text">{InteractionStore.checkTransactionStatus()}</span>
            </> :
            <>
                <div className="consent-wrapper">
                    <h3 className="consent-title">{InteractionStore.checkInteraction().client.client_name} {LangStore.getInstance().t("authorization.stages.consent.description")}</h3>
                    <ul className="consent-scopes">
                        {decodedScopes.length > 0 && decodedScopes.reverse().map((value, index) => {
                            if (value === 'VelasAccountProgram:Transfer') return <li className="consent-scopes-item" key={index}>Transfer</li>
                            else if (value === 'authorization') return <li className="consent-scopes-item" key={index}>Authorization on {InteractionStore.checkInteraction().client.client_name}</li>
                            else if (value === 'openid') return <li className="consent-scopes-item" key={index}>Account info</li>
                            else return <li className="consent-scopes-item" key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <hr className="box-black-hr"/>
                <div className="consent-button-wrapper">
                    <NotchButton type="button" color="black" onClick={() => FunctionStore.giveConsent()}
                                 value={LangStore.getInstance().t("authorization.buttons.approve")}/>
                </div>
            </>
    )
}

export default observer(InteractionConsent)