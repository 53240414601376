import {useEffect} from "react";
import { agent } from '../../functions/agent';

export default function IFramePage() {

    useEffect(()=>{
        agent.addEventListener();
    }, [])

    return (
        <div>
        </div>
    )
}