import "./index.sass"
import {observer} from "mobx-react";
import StoreContext from "../../store/RootStore";
import {useState} from "react";
import Dropdown from "../dropdown";
import {isMobile} from "react-device-detect";

function IDBTutorial() {
    // this component is called when the indexDB.open() fails due to security issues and provides solutions on how to fix it.
    const {LangStore, InteractionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.

    return (
        <div className="idb-tutorial-wrapper">
            <p className="idb-description">{LangStore.getInstance().t("authorization.stages.idb_error.description")}</p>
            <Dropdown title={LangStore.getInstance().t("authorization.stages.idb_error.solution1.title")}>
                {isMobile ?
                    <ul className="idb-solution">
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution1.step1_mobile")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-1-1-mobile.jpg").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution1.step2_mobile")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-1-2-mobile.jpg").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">
                                {LangStore.getInstance().t("authorization.stages.idb_error.solution1.step3_mobile")}&nbsp;
                                <span className="idb-solution-item-text-underline"
                                      onClick={()=>{
                                          if (InteractionStore.checkInteraction().params.mode === "popup") {
                                             window.close();
                                          } else if (InteractionStore.checkInteraction().params.mode === "redirect") {
                                              window.location.href = InteractionStore.checkInteraction().params.redirect_uri;
                                          }
                                      }}>
                                    {InteractionStore.checkInteraction().client ? InteractionStore.checkInteraction().client.client_name : ""}
                                </span>&nbsp;
                                {LangStore.getInstance().t("authorization.stages.idb_error.solution1.step3_1_mobile")}
                            </span>
                        </li>
                    </ul> :
                    <ul className="idb-solution">
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution1.step1")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-1-1-desktop.png").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution1.step2")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-1-2-desktop.png").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution1.step3")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-1-3-desktop.png").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">
                                {LangStore.getInstance().t("authorization.stages.idb_error.solution1.step4")}&nbsp;
                                <span className="idb-solution-item-text-underline"
                                      onClick={()=>{
                                          if (InteractionStore.checkInteraction().params.mode === "popup") {
                                              window.close();
                                          } else if (InteractionStore.checkInteraction().params.mode === "redirect") {
                                              window.location.href = InteractionStore.checkInteraction().params.redirect_uri;
                                          }
                                      }}>
                                    {InteractionStore.checkInteraction().client ? InteractionStore.checkInteraction().client.client_name : ""}
                                </span>&nbsp;
                                {LangStore.getInstance().t("authorization.stages.idb_error.solution1.step4_1")}
                            </span>
                        </li>
                    </ul>
                }
            </Dropdown>
            <Dropdown title={LangStore.getInstance().t("authorization.stages.idb_error.solution2.title")}>
                {isMobile ?
                    <ul className="idb-solution">
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution2.step1_mobile")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-2-1-mobile.jpg").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution2.step2_mobile")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-2-2-mobile.jpg").default} alt="" className="idb-solution-item-image"/>
                        </li>
                    </ul> :
                    <ul className="idb-solution">
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution2.step1")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-2-1-desktop.png").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution2.step2")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-2-2-desktop.png").default} alt="" className="idb-solution-item-image"/>
                        </li>
                        <li className="idb-solution-item">
                            <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution2.step3")}</span>
                            <img src={require("../../assets/images/IDBTutorial/solution-2-3-desktop.png").default} alt="" className="idb-solution-item-image"/>
                        </li>
                    </ul>
                }
            </Dropdown>
            <Dropdown title={LangStore.getInstance().t("authorization.stages.idb_error.solution3.title")}>
                <ul className="idb-solution">
                    <li className="idb-solution-item">
                        <span className="idb-solution-item-text">{LangStore.getInstance().t("authorization.stages.idb_error.solution3.description")}</span>
                        <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer" className="idb-solution-item-text idb-solution-item-link">{LangStore.getInstance().t("authorization.stages.idb_error.solution3.download")} Google Chrome</a>
                        <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer" className="idb-solution-item-text idb-solution-item-link">{LangStore.getInstance().t("authorization.stages.idb_error.solution3.download")} Mozilla Firefox</a>
                    </li>
                </ul>
            </Dropdown>
        </div>
    )
}

export default observer(IDBTutorial)