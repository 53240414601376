import BigNumber from 'bignumber.js'

import  {ReactComponent as IconExecute} from '../../../assets/images/history/execute.svg';
import  {ReactComponent as IconLogin} from '../../../assets/images/history/login.svg';
import  {ReactComponent as IconLogout} from '../../../assets/images/history/logout.svg';
import  {ReactComponent as IconSend} from '../../../assets/images/history/send.svg';
import  {ReactComponent as IconReceive} from '../../../assets/images/history/receive.svg';

import "./index.sass"

export default function Transaction({data}) {
    return (
        <div className="history-item" onClick={()=> redirect(data)}>
            <div className="history-item-left">
                {getIcon(data.name)}
            </div>

            <div className="history-item-right">
                <div className="history-item-name">{transactionNamePretify(data.name)}</div>
                <div className="history-item-hash">{getHash(data.hash)}</div>
            </div>

            { data.amount && 
                <div className="history-item-amount">
                    {data.name === 'Receive funds' && <>+</>}
                    {data.name === 'Receive tokens' && <>+</>}
                    {data.name === 'Send funds' && <>-</>}
                    {data.name === 'Send tokens' && <>-</>}
                    {getAmount(data)} {getTokenName(data)}
                </div>
            }
        </div>
    )
};

const redirect = (data) => {
    if (!process.env.REACT_APP_EXPLORER_HOST || !process.env.REACT_APP_EXPLORER_CLUSTER || !process.env.REACT_APP_EVM_EXPLORER_HOST) {
        console.log("No env for explorer:", process.env.REACT_APP_EXPLORER_HOST, process.env.REACT_APP_EXPLORER_CLUSTER)
        return null;
    };

    if(data.type === 'native') {
        window.open(`${process.env.REACT_APP_EXPLORER_HOST}/tx/${data.hash}?cluster=${process.env.REACT_APP_EXPLORER_CLUSTER}`, "_blank")
    } else {
        window.open(`${process.env.REACT_APP_EVM_EXPLORER_HOST}/tx/${data.hash}`, "_blank")
    };
};

const transactionNamePretify = (name) => {

    switch(name) {
        case 'Initialize':
            return 'Initialize account'

        case 'AddOperational':
            return 'Login'

        case 'AddProgram':
            return 'Add program'

        case 'AddOwner':
            return 'Add owner'

        case 'ExtendOperational':
            return 'Extend scopes'

        case 'MergeOperational':
            return 'Merge'

        case 'ReplaceOwner':
            return 'Replace owner'

        case 'RemoveOwner':
            return 'Remove owner'

        case 'RemoveOperational':
            return 'Logout'

        case 'RemoveProgramPermission':
            return 'Remove permission'
        
        case 'SponsorAndExecute':
            return 'Sponsor and Execute'

        default:
            return name
    };
};

const getIcon = (name = 'NaN') => {
    switch(name) {
        case "Receive tokens":
        case "Receive funds":
            return <IconReceive className='history-icon'/>

        case "Send tokens":
        case "Send funds":
            return <IconSend className='history-icon'/>

        case "AddOperational":
            return <IconLogin className='history-icon'/>
        case "RemoveOperational":
            return <IconLogout className='history-icon'/>
        case "Execute":
            return <IconExecute className='history-icon'/>
        default:
            return <IconExecute className='history-icon'/>;
    };
};

const getHash = (item) => {
    return `${item.slice(0,11)}..${item.substr(-11)}`
};

const getTokenName = (data) => {
    if (data.tokenSymbol) return data.tokenSymbol;

    return 'VLX'
};

const getAmount = (data) => {
    let decimal = 10;

    if (data.type === 'evm') decimal = 18;

    if (data.tokenDecimals) decimal = Number(data.tokenDecimals);

    return new BigNumber(data.amount.toLocaleString('fullwide', { useGrouping: false }) + 'e-' + decimal)
        .decimalPlaces(5, BigNumber.ROUND_FLOOR)
        .toString();
};