import './index.sass'
import AccountTicker from "../auth/accountTicker";
import {ReactComponent as LogoWhite} from "../../assets/images/logo-white.svg";
import {ReactComponent as LogoGlitch} from "../../assets/images/logo-glitch.svg";

export default function DocumentContainer({children}) {
    return (
        <div className="document-page">
            <AccountTicker/>
            <LogoWhite className="document-header-logo"/>
            <div className="document-content-wrapper">
                <div className="document-content">
                    {children}
                </div>
            </div>
            <LogoGlitch className="document-logo-glitch"/>
        </div>
    )
}