import './index.sass'
import {observer} from "mobx-react";
import StoreContext from "../../../store/RootStore";
import NotchButton from "../../notchButton";
import Preloader from "../../preloader";
import {useEffect, useState} from "react";
import Modal from "../../modal";

function ExpressSignup() {
    const {InteractionStore, LangStore, FunctionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setModalOpen(true);
        }, 1500)
    }, []);

    return (
        <div className="sign-up-container">
            <Preloader/>
            <span className="preloader-text">{LangStore.getInstance().t("authorization.stages.express_sign_up.description")}</span>
            <Modal header={LangStore.getInstance().t("authorization.stages.express_sign_up.warning.title")} headerType="warning"
                   text={LangStore.getInstance().t("authorization.stages.express_sign_up.warning.text")} opened={modalOpen}>
                <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.agree")}
                             color="black"
                             onClick={() => {
                                 setModalOpen(false);
                                 FunctionStore.createEphemeralAccount();
                             }}
                />
                <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.cancel")}
                             color="white"
                             onClick={() => {
                                 setModalOpen(false);
                                 InteractionStore.setCustomStage("login");
                                 InteractionStore.deleteCustomHistory();
                             }}
                />
            </Modal>
        </div>
    )
}


export default observer(ExpressSignup)