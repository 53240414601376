import { createContext, useContext } from 'react';
import LangStore from './LangStore';
import InteractionStore from "./InteractionStore";
import FunctionStore from "./FunctionStore";
import AccountStore from "./AccountStore";

const IStoreContext = createContext({LangStore, InteractionStore, FunctionStore, AccountStore});

export default function StoreContext () {
    return useContext(IStoreContext);
};