import {observer} from "mobx-react";
import StoreContext from "../../../../store/RootStore";
import AccountDropDown from "../../accounts/accountDropDown";
import NotchButton from "../../../notchButton";
import Preloader from "../../../preloader";

function SeedRelatedAccounts({userData}) {
    const {InteractionStore, FunctionStore, LangStore, AccountStore} = StoreContext();

    return (
        <> {InteractionStore.checkTransactionStatus() ? <><Preloader/><span className="preloader-text">{InteractionStore.checkTransactionStatus()}</span></> :
            <>
                <div className="accounts-wrapper">
                    {userData ? userData.addresses.map((address, index) => {
                        return <AccountDropDown account={address} onClick={() => FunctionStore.addAcccount({
                            address: address,
                            owner_key: userData.owner_key,
                            owner_private: userData.owner_private
                        })} disabled={!!InteractionStore.checkTransactionStatus()} key={`account-${index}`}/>
                    }) : <span>incorrect input data</span>}
                </div>
                {!AccountStore.getCurrentStage() ? <>
                <hr className="box-black-hr"/>
                <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.more")} disabled={!!InteractionStore.checkTransactionStatus()} color="white"
                             onClick={() => InteractionStore.setCustomStage("seed_add")}/>
                <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.create.new")} disabled={!!InteractionStore.checkTransactionStatus()} color="black"
                             onClick={() => {
                                 InteractionStore.setCustomStage("login");
                                 InteractionStore.deleteCustomHistory();
                             }}/>
                </> : ""}
            </>
        }
        </>
    )
}

export default observer(SeedRelatedAccounts)