export default class StorageHandler {
    constructor() {
        this.storage = window.localStorage;
    }

    getItem(key){
        try {
            return JSON.parse(this.storage.getItem(key));
        } catch (e) {
            console.error(e);
        }
    }

    getItems() {
        const keys = Object.keys(this.storage);
        let values = {}, i = keys.length;
        while ( i-- ) {
            try {
                values[keys[i]] = JSON.parse(this.storage.getItem(keys[i]));
            } catch (e) {
                values[keys[i]] = this.storage.getItem(keys[i])
            }
        }

        return values;
    }

    removeItem(key) {
        try {
            return this.storage.removeItem(key);
        } catch (e) {
            console.error(e)
        }
    }

    setItem(key,json,options) {
        try {
            const value = JSON.stringify({...json, ...options});
            return this.storage.setItem(key, value);
        } catch (e) {
            console.error(e)
        }
    }
}