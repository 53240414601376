import DocumentContainer from "../../components/documentContainer";
import ReactMarkdown from 'react-markdown'
import {useEffect, useState} from "react";
import markdown from '../../assets/text/terms&privacy/terms.md';

export default function Terms() {
    const [content, setContent] = useState(null)
    useEffect(()=>{
        fetch(markdown).then(res => res.text()).then(text => setContent(text));
    },[])

    return (
        <DocumentContainer>
            {content ? <ReactMarkdown className="document-content-md" children={content}/> : ""}
        </DocumentContainer>
    )
}