import { makeAutoObservable } from "mobx";

class InteractionStore {
    interaction = {};
    interactionStage = "";
    customStage = "";
    previousCustomStage = [];
    transactionStatus = null;
    redirectUri = "";
    authError = null;

    constructor() {
        makeAutoObservable(this);
    }

    checkInteraction() {
        return this.interaction;
    }

    checkInteractionStage() {
        return this.interactionStage;
    }

    checkCustomStage() {
        return this.customStage;
    }

    checkTransactionStatus() {
        return this.transactionStatus;
    }

    checkRedirectUri() {
        return this.redirectUri;
    }

    checkAuthError() {
        return this.authError;
    }

    setInteraction(interaction) {
        this.interaction = interaction;
    }

    setInteractionData(interaction) {
        this.interaction = interaction;
        this.interactionStage = this.interaction.stage;
        this.redirectUri = this.interaction.params.redirect_uri || [];
    }

    setTransactionStatus(value) {
        this.transactionStatus = value
    }

    setCustomStage(value) {
        if (this.customStage) {
            this.previousCustomStage.push(this.customStage);
        }
        this.customStage = value;
    }

    setAuthError(error) {
        this.authError = [error.message, String(error)];
        this.customStage = "auth_error";
        this.interactionStage = "auth_error";
        this.transactionStatus = "auth_error";
    }

    deleteCustomHistory() {
        this.previousCustomStage = [];
    }

    customGoBack() {
        if (this.previousCustomStage !== [] && this.previousCustomStage[this.previousCustomStage.length-1] !== "sign_up") {
            this.customStage = this.previousCustomStage[this.previousCustomStage.length-1]
            this.previousCustomStage.pop();
        } else if(this.previousCustomStage !== [] && this.previousCustomStage[this.previousCustomStage.length-1] === "sign_up") {
            //workaround for signup to exclude navigating to an empty component
            this.previousCustomStage.pop();
            this.customStage = this.previousCustomStage[this.previousCustomStage.length-1]
            this.previousCustomStage.pop();
        } else {
            this.customStage = null;
        }
    }
}

export default new InteractionStore();

