import './index.sass'
import {observer} from "mobx-react";
import StoreContext from "../../store/RootStore";
import ReactDOM from "react-dom";
import {FlagIcon} from "react-flag-kit";
import { ReactComponent as LogoWhite } from '../../assets/images/logo-white.svg'
import getTranslations from "../../functions/getTranslations";

function ChooseLangModal({opened, setOpened}) {
    const {LangStore} = StoreContext();
    const languages = getTranslations(false, true);
    return (opened ? ReactDOM.createPortal(
            <div className={`lang-modal-overlay ${opened ? "lang-modal-overlay-active" : ""}`}>
                <LogoWhite className="lang-modal-overlay-logo"/>
                <div className="lang-modal-border-wrapper">
                    <div className="lang-modal-wrapper" role="dialog" aria-modal aria-hidden>
                        <div className="lang-modal-header">
                            <h4 className="lang-modal-header-title">{LangStore.getInstance().t("authorization.lang.modal.title")}</h4>
                            <div className="lang-modal-header-close" onClick={()=>setOpened(false)}>
                                <div className="lang-modal-header-close-line"/>
                                <div className="lang-modal-header-close-line"/>
                            </div>
                        </div>
                        <hr className="lang-modal-header-hr"/>
                        <div className="lang-modal-content">
                            {languages.map((item, index) => {
                                return (
                                    <div className="lang-modal-item" key={"language-" + index} onClick={() => {
                                        LangStore.changeLang(item.namespace);
                                        setOpened(false);
                                    }}>
                                        <FlagIcon code={item.country_code} size="21" className="lang-modal-flag"/>
                                        <span className="lang-modal-name">{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            , document.body) : null
    )
}

export default observer(ChooseLangModal)