import './index.sass'
import {observer} from "mobx-react";
import Preloader from "../../components/preloader";
import "../../components/auth/box/index.sass"
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Box from "../../components/box";
import {agent} from "../../functions/agent";

function TokenToOperational() {
    const [error,  setError]  = useState(false);
    const [result, setResult] = useState(false);

    const currentLocation = useLocation();

    // eslint-disable-next-line no-native-reassign
    process = async () => {
        const tempToken = currentLocation.search.replace('?', '');
        const token = tempToken.substring(tempToken.indexOf('=') + 1)

        if (token) {
            try {
                const {
                    error,
                    description,
                    account,
                    session_key,
                    op_key,
                    scopes,
                    csrf_token,
                    transactions_sponsor_api_host,
                    transactions_sponsor_pub_key
                } = await agent.process("mobile_token", {token});

                if (error) {
                    window.history.replaceState({}, document.title, window.location.pathname);
                    setError(description);
                } else {
                    agent.provider.client.sendMessage({
                        transaction_name: 'addOperationalAddressTransaction',
                        params: {
                            transaction_signer:         session_key,
                            velas_account:              account,
                            new_operational_public_key: op_key,
                            scopes,
                            transactions_sponsor_pub_key,
                        },
            
                        transactions_sponsor_api_host,
                        csrf_token,
            
                        cb: (error, success) => {
                            if (success) {
                               console.log(success.transaction_id)
                               window.history.replaceState({}, document.title, window.location.pathname);
                               setResult(account)
                            } else {
                                window.history.replaceState({}, document.title, window.location.pathname);
                                setError(error.description);
                            };
                        },
                    });
                };
            } catch (e) {
                window.history.replaceState({}, document.title, window.location.pathname);
                setError(e.message);
            };
        } else {
            setError("Invalid access code");
        };
    };

    useEffect(process, [currentLocation]);

    return (
        <div className="account-info">
            <Box>
                {  error && <p>Error: { error }</p> }
                { !error && !result && <Preloader/> }
                { !error && result  &&
                    <main className="account-info-content">
                        <div className="account-info-tabs">
                            <h5 className="account-info-tab">Authorized with account:</h5>
                            <h6>{result}</h6>
                            <p className="account-info-tab">Just close browser to return</p>                            
                        </div>
                    </main>
                }
            </Box>
        </div>
    );
};

export default observer(TokenToOperational);