import './index.sass';
import StoreContext from "../../../store/RootStore";
import {observer} from "mobx-react";
import {useState} from "react";
import NotchBlock from "../../notchBlock";
import BoxHeader from "./boxHeader";
import BoxFooter from "../../auth/box/boxFooter";
import {ReactComponent as BackupIcon} from '../../../assets/images/alert-icon.svg';
import {ReactComponent as AddressIcon} from '../../../assets/images/address-icon.svg';
import {ReactComponent as EyeIcon} from '../../../assets/images/eye-icon.svg';
import AddressSwiper from "../addressSwiper";

function Box({stage, children}) {
    const {LangStore, AccountStore, InteractionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.

    function renderHeaderContent(stage) {
        switch (stage) {
            case "":
                return;

            case "home":
            case "sessions":
            case "tokens":
                return <>
                    {AccountStore.getActiveAccount().guest ?
                        <div className="info-header-guest">
                            <EyeIcon className="info-header-guest-icon"/>
                            <span
                                className="info-header-guest-text">{LangStore.getInstance().t("account.info.stages.guest.title")}</span>
                        </div>
                        : ""}
                    <div className="info-header">
                        <div className="info-header-account">
                            <div className="info-header-account-wrapper" onClick={() => (
                                AccountStore.getAccounts().length > 0 ? AccountStore.setCurrentStage("accounts") : null
                            )}>
                                {AccountStore.getAccounts().length > 0 ? <AddressIcon className="info-header-account-icon"/> : ""}
                                <h6 className={`info-header-account-title ${AccountStore.getActiveAccount().type === "operational" || AccountStore.getActiveAccount().guest ? "info-header-account-title-full" : ""}`}>{AccountStore.getActiveAccountData().readableEvmAddress}</h6>
                            </div>
                            {AccountStore.getActiveAccount().type !== "operational" && !AccountStore.getActiveAccount().guest ?
                                <button className="info-header-account-backup" onClick={() => {
                                    AccountStore.setCurrentStage("backup_account");
                                }}>
                                    <div className="info-header-account-backup-wrapper">
                                        <BackupIcon className="info-header-account-backup-icon"/>
                                        <span
                                            className="info-header-account-backup-text">{LangStore.getInstance().t("authorization.buttons.account.backup")}</span>
                                    </div>
                                </button>
                                : ""}
                        </div>
                        <h2 className="info-header-title">{AccountStore.getActiveAccountBalance() ? AccountStore.getActiveAccountBalance() + " VLX" : LangStore.getInstance().t("authorization.transaction.statuses.loading")}</h2>
                        <AddressSwiper evmAddress={AccountStore.getActiveAccountData().evmAddress}
                                       nativeAddress={AccountStore.getActiveAccount().account}/>
                    </div>
                </>

            case "restore_account":
                if (InteractionStore.checkCustomStage() === "seed_related_accounts") {
                    return <>
                        <h1 className="box-header-title">{LangStore.getInstance().t(`authorization.stages.seed_related_accounts.title`)}</h1>
                        <hr className="box-black-hr box-black-hr-top"/>
                    </>
                } else {
                    return;
                }

            // implement later
            // case "tokens":
            //     return <div className="info-header">
            //
            //     </div>

            case "transfer":
            case "accounts":
            case "settings":
                return <>
                    <h1 className="box-header-title">{LangStore.getInstance().t(`account.info.stages.${stage}.title`)}</h1>
                    <hr className="box-black-hr box-black-hr-top"/>
                </>
            default:
                return;
        }

    }

    return (
        <div className="account-info-box-wrapper">
            <BoxHeader stage={stage}/>
            <NotchBlock type="main" additionalClassName={AccountStore.getActiveAccount() && AccountStore.getActiveAccount().guest && (stage === "home" || stage === "tokens") ? "notch-block-main-guest" : null}>
                <div className="box-shadow"/>
                <div className="box-header">
                    {renderHeaderContent(stage)}
                </div>
                <div className="box-content">
                    {children}
                </div>
            </NotchBlock>
            <BoxFooter/>
        </div>
    )
}

export default observer(Box);