import './index.sass'
import {useState} from "react";
import {ReactComponent as DownArrow} from "../../assets/images/black-down-arrow.svg";
import {Collapse} from "react-collapse";

export default function Dropdown({title, className, children}) {
    const [open, setOpen] = useState();

    return (
        <div className={`dropdown-wrapper ${className ? className : ""}`}>
            <div className={`dropdown ${open ? "dropdown-active" : ""}`}>
                <span className="dropdown-title">{title}</span>
                <div className="dropdown-arrow-wrapper" onClick={() => {
                    setOpen(!open)
                }}>
                    <DownArrow className={`dropdown-arrow ${open ? "dropdown-arrow-active" : ""}`}/>
                </div>
            </div>
            <Collapse isOpened={open}>
                <div className="dropdown-content">
                    {children}
                </div>
            </Collapse>
        </div>
    )
}