import './index.sass'
import StoreContext from "../../../../store/RootStore";
import {observer} from "mobx-react";
import NotchButton from "../../../notchButton";
import {useState} from "react";
import Preloader from "../../../preloader";

function InteractionMerge() {
    const {LangStore, InteractionStore, FunctionStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const merge = InteractionStore.checkInteraction().merge;
    const currentInteraction = InteractionStore.checkInteraction();

    return (InteractionStore.checkTransactionStatus() ?
        <>
            <Preloader/>
            <span className="preloader-text">{InteractionStore.checkTransactionStatus()}</span>
        </> :
        <>
            <div className="merge-wrapper">
                <h3 className="merge-title">{LangStore.getInstance().t("authorization.stages.merge.description")} {currentInteraction.session.account.slice(0, currentInteraction.session.account.length / 4).concat("........").concat(currentInteraction.session.account.slice(currentInteraction.session.account.length - currentInteraction.session.account.length / 4, currentInteraction.session.account.length))}</h3>
                <div className="merge-content-wrapper">
                    <p>{LangStore.getInstance().t("authorization.stages.merge.new.session")}<br/><b>... {merge.operationalAddress.slice(-20)}</b><br/><br/> {LangStore.getInstance().t("authorization.stages.merge.merged")}<br/><b>... {merge.operationalAddressMergeWith.slice(-20)}</b></p>
                </div>
                <div className="consent-button-wrapper">
                    <NotchButton type="button" color="black" onClick={()=>FunctionStore.mergeAccounts(InteractionStore.checkInteraction().session, merge.operationalAddressMergeWith, merge.operationalAddress)} value={LangStore.getInstance().t("authorization.buttons.approve")}/>
                </div>
            </div>
        </>
    )
}

export default observer(InteractionMerge);