import './index.sass'

export default function NotchBlock({type, style, additionalClassName, children}) {
    return (
        <div
            className={(type === "main" ? "notch-block-main" : type === "border" ? "notch-block notch-block-border" : "notch-block") + (additionalClassName ? (" " + additionalClassName) : "")}
            style={style}>
            {children}
        </div>
    )
}