import './index.sass'
import StoreContext from "../../../store/RootStore";
import {useState} from "react";
import {observer} from "mobx-react";
import NotchButton from "../../notchButton";
import SeedRelatedAccounts from "./seedRelatedAccounts";
import SeedAddAccount from "./seedAddAccount";
import FunctionStore from "../../../store/FunctionStore";

function Seed({phrase, phraseValidator, stageAfterVerify, actionAfterVerify}) {
    const {InteractionStore, LangStore} = StoreContext();
    // eslint-disable-next-line no-unused-vars
    const [langStore, setLangStore] = useState(LangStore.language); //a trigger to re-render the component when language is changed. Poor implementation, needs to be changed.
    const phraseNumber = 12; // Change this if more words are needed
    const initialInputData = Array(phraseNumber).fill("");
    const [inputData, setInputData] = useState(initialInputData);
    const [userData, setUserData] = useState({});

    function inputChangeHandler(ev, index) {
        const values = [...inputData];

        if (index === 0) {
            const phrase = ev.target.value.split(" ")
            values.forEach((el, index) => {
                values[index] = phrase[index] || "";
            })
            if (phrase.length > inputData.length) {
                values[inputData.length - 1] = phrase.slice(inputData.length, phrase.length).join(" ");
            }
        } else {
            values[index] = ev.target.value.split(" ").join("");
        }

        setInputData(values);
    }

    function renderSeedInputs(phraseNumber) {
        const inputsArray = [];
        for (let i = 1; i <= phraseNumber; i++) {
            inputsArray.push(<input type="text" className="seed-form-input"
                                    value={inputData[i - 1]} onChange={(ev) => inputChangeHandler(ev, i - 1)}
                                    placeholder={`${LangStore.getInstance().t("authorization.stages.seed_restore.word")} #${i}`}/>);
        }
        return inputsArray.map((item, index) => {
            return (
                <label className="seed-form-input-label" key={`seed-form-input-label-${index}`}>
                    {item}
                </label>
            )
        })
    }

    function renderSeed(phrase) {
        const seedArray = phrase.split(" ");
        return seedArray.map((item, index) => {
            return (
                <div className="seed-form-word-wrapper" key={`word-${index}`}>
                    <span className="seed-form-word-number">{index + 1}</span>
                    <span className="seed-form-word">{item}</span>
                </div>
            )
        })
    }

    async function confirmSeed(userSeed, correctSeed, seedValidator) {

        if ((correctSeed && userSeed === correctSeed) || (seedValidator && await seedValidator(userSeed))) {
            if (stageAfterVerify) {
                InteractionStore.setCustomStage(stageAfterVerify)
            } else if (actionAfterVerify) {
                await actionAfterVerify(userSeed);
            }
        } else if (correctSeed && userSeed !== correctSeed) {
            FunctionStore.handleError(LangStore.getInstance().t("authorization.notifications.seed.match"), true)
        }
    }

    async function verifySeed(seed) {
        const accountData = await FunctionStore.validateSeed(seed);
        if (accountData) {
            setUserData(accountData);
            InteractionStore.setCustomStage("seed_related_accounts");
        }
    }

    function renderSeedStage(stage, userData, setUserData) {
        switch (stage) {
            case "seed_restore":
                return (
                    <div className="seed-wrapper">
                        <form className="seed-form" onSubmit={(e) => {
                            e.preventDefault();
                            verifySeed(inputData.join(" "), setUserData);
                        }}>
                            <div className="seed-form-border-wrapper">
                                <div className="seed-form-wrapper">
                                    <div className="seed-form-header">
                                        <h4 className="seed-form-header-title">{LangStore.getInstance().t("authorization.stages.seed_restore.header.title")}</h4>
                                        <hr className="seed-form-hr"/>
                                    </div>
                                    <div className="seed-form-input-wrapper">
                                        {renderSeedInputs(phraseNumber)}
                                    </div>
                                </div>
                            </div>
                            <div className={`seed-form-clear ${inputData.some(item => item.length > 0) ? "seed-form-clear-active" : ""}`}>
                                <button className="seed-form-clear-button" onClick={(e)=>{
                                    e.preventDefault();
                                    setInputData(initialInputData);
                                }}>
                                    <span className="seed-form-clear-text">{LangStore.getInstance().t("authorization.stages.seed_restore.clear")}</span>
                                    <div className="seed-form-clear-cross">
                                        <div className="seed-form-clear-cross-line"/>
                                        <div className="seed-form-clear-cross-line"/>
                                    </div>
                                </button>
                            </div>
                            <p className="seed-form-text">{LangStore.getInstance().t("authorization.stages.seed_restore.description")}</p>
                            <hr className="box-black-hr"/>
                            <NotchButton type="submit" value={LangStore.getInstance().t("authorization.buttons.verify")}
                                         disabled={!!InteractionStore.checkTransactionStatus()} color="black"/>
                        </form>
                    </div>
                )

            case "seed_related_accounts":
                return <SeedRelatedAccounts userData={userData}/>

            case "seed_add":
                return <SeedAddAccount userData={userData} setUserData={setUserData}/>

            case "seed_show":
                return (
                    <div className="seed-wrapper">
                        <form className="seed-form" onSubmit={(e) => {
                            e.preventDefault();
                            InteractionStore.setCustomStage("seed_verify")
                        }}>
                            <div className="seed-form-border-wrapper">
                                <div className="seed-form-wrapper">
                                    <div className="seed-form-header">
                                        <h4 className="seed-form-header-title">{LangStore.getInstance().t("authorization.stages.seed_show.subtitle")}</h4>
                                        <hr className="seed-form-hr"/>
                                    </div>
                                    <div className="seed-form-input-wrapper">
                                        {renderSeed(phrase)}
                                    </div>
                                </div>
                            </div>
                            <p className="seed-form-text">{LangStore.getInstance().t("authorization.stages.seed_show.text")}</p>
                            <hr className="box-black-hr"/>
                            <NotchButton type="button" value={LangStore.getInstance().t("authorization.buttons.copy")}
                                         disabled={!!InteractionStore.checkTransactionStatus()} color="white"
                                         onClick={(e) => {
                                             e.preventDefault();
                                             navigator.clipboard.writeText(phrase);
                                             FunctionStore.handleSuccess(LangStore.getInstance().t("authorization.notifications.seed.copied"))
                                         }}/>
                            <NotchButton type="submit" value={LangStore.getInstance().t("authorization.buttons.iwrote")}
                                         disabled={!!InteractionStore.checkTransactionStatus()} color="black"/>
                        </form>
                    </div>
                )

            case "seed_verify":
            case "empowerment_seed":
                return (
                    <div className="seed-wrapper">
                        <form className="seed-form" onSubmit={(e) => {
                            e.preventDefault();
                            confirmSeed(inputData.join(" "), phrase, phraseValidator);
                        }}>
                            <div className="seed-form-border-wrapper">
                                <div className="seed-form-wrapper">
                                    <div className="seed-form-header">
                                        <h4 className="seed-form-header-title">{LangStore.getInstance().t("authorization.stages.seed_restore.header.title")}</h4>
                                        <hr className="seed-form-hr"/>
                                    </div>
                                    <div className="seed-form-input-wrapper">
                                        {renderSeedInputs(phraseNumber)}
                                    </div>
                                </div>
                            </div>
                            <div className={`seed-form-clear ${inputData.some(item => item.length > 0) ? "seed-form-clear-active" : ""}`}>
                                <button className="seed-form-clear-button" onClick={(e)=>{
                                    e.preventDefault();
                                    setInputData(initialInputData);
                                }}>
                                    <span className="seed-form-clear-text">{LangStore.getInstance().t("authorization.stages.seed_restore.clear")}</span>
                                    <div className="seed-form-clear-cross">
                                        <div className="seed-form-clear-cross-line"/>
                                        <div className="seed-form-clear-cross-line"/>
                                    </div>
                                </button>
                            </div>
                            <p className="seed-form-text">{stage === "seed_verify" ? LangStore.getInstance().t("authorization.stages.seed_restore.description") : LangStore.getInstance().t("authorization.stages.empowerment_seed.description")}</p>
                            <hr className="box-black-hr"/>
                            <NotchButton type="submit" value={LangStore.getInstance().t("authorization.buttons.verify")}
                                         disabled={!!InteractionStore.checkTransactionStatus()} color="black"/>
                        </form>
                    </div>
                )
            default:
                return;
        }
    }

    return (
        <>
            {renderSeedStage(InteractionStore.checkCustomStage(), userData, setUserData)}
        </>
    )
}

export default observer(Seed);